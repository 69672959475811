import { useTranslation } from 'react-i18next';
import { mapClassnameForMobile } from '../../../utils';
import { HeaderLayout } from '../../common/header';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { useAppSelector } from '../../../hooks';
import { selectUserInfo } from '../../../services/user';
import { CustomInput } from '../../common/inputs';

const AccountInfoLayout = () => {
	const userInfo = useAppSelector(selectUserInfo);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.INDEX);
	};

	const mapParamId = (paramId: string) => {
		switch (paramId) {
			case 'countryOfResidence':
				return t('verification_citizenship');
			case 'firstName':
				return t('verification_firstName');
			case 'lastName':
				return t('verification_lastName');
			case 'middleName':
				return t('verification_patronymic');
			case 'phone':
				return t('login_phone');
			case 'email':
				return t('login_email');
			case 'birthDate':
				return t('verification_dateOfBirth');
			default:
				return '';
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innetContainerMainText">{t('accountInfo_title')}</h1>

						{userInfo.map((param) => (
							<CustomInput
								type="text"
								id={param.paramId}
								isRequired={true}
								label={mapParamId(param.paramId)}
								className="userInfoInputField"
								value={param.paramValue}
								isDisabled
								isStartIcon={param.paramId === 'countryOfResidence'}
								isNonCloseIcon
								imgUrl={
									param.paramId === 'countryOfResidence'
										? param?.icon
										: undefined
								}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountInfoLayout;
