import { useTranslation } from 'react-i18next';
import { TAsset, TNonMainAccountNotEmpty } from '../../../../types/types';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
	selectBasePrecision,
	selectCommissionAccountRests,
	selectGlobalAsset,
	selectMainAccountRests,
	selectMarginAccountsRests,
	selectSpotAccountsRests,
} from '../../../../services/wallet';
import { CustomButton } from '../../../common/buttons';
import {
	selectIsNonMainAccountsNotEmpty,
	selectTotalAmount,
	selectTotalBaseAmount,
	setIsNonMainAccountsNotEmpty,
	setTotalAmount,
	setTotalBaseAmount,
} from '../../../../services/market';
import { formatNumber } from '../../../../utils';

interface Props {
	code: string;
	isLoading: boolean;
	isVerified: boolean;
	handleAssetDetailsTransferModalOpen: () => void;
}

const AssetDetailsTotalCard = ({
	code,
	isLoading,
	isVerified,
	handleAssetDetailsTransferModalOpen,
}: Props) => {
	const { t } = useTranslation();
	const globalAsset = useAppSelector(selectGlobalAsset);
	const basePrecision = useAppSelector(selectBasePrecision);
	const dispatch = useAppDispatch();

	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const commissionCurrencies = useAppSelector(selectCommissionAccountRests);
	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const marginCurrencies = useAppSelector(selectMarginAccountsRests);

	const totalAmount = useAppSelector(selectTotalAmount);
	const totalBaseAmount = useAppSelector(selectTotalBaseAmount);
	const isNonMainAccountsNotEmpty = useAppSelector(selectIsNonMainAccountsNotEmpty);

	const handleTotalAmountCalculate = (
		assets: TAsset[],
		accType?: keyof TNonMainAccountNotEmpty
	) => {
		const findedAsset = assets.find((asset) => asset.code === code);
		if (findedAsset) {
			dispatch(setTotalAmount(findedAsset?.available + findedAsset?.reserved));
			dispatch(setTotalBaseAmount(findedAsset?.baseAvailable));
		}

		if (accType) {
			dispatch(
				setIsNonMainAccountsNotEmpty({
					accType: accType,
					value:
						assets.some((asset) => asset.code === code) &&
						Number(assets.find((asset) => asset.code === code)?.available) >
							0,
				})
			);
		}
	};

	useEffect(() => {
		if (mainCurrencies?.assets && isVerified) {
			handleTotalAmountCalculate(mainCurrencies?.assets);
		}
	}, [mainCurrencies]);

	useEffect(() => {
		if (spotCurrencies?.assets && isVerified) {
			handleTotalAmountCalculate(spotCurrencies?.assets, 'spot');
		}
	}, [spotCurrencies]);

	useEffect(() => {
		if (marginCurrencies?.assets && isVerified) {
			handleTotalAmountCalculate(marginCurrencies?.assets, 'margin');
		}
	}, [marginCurrencies]);

	useEffect(() => {
		if (commissionCurrencies?.assets && isVerified) {
			handleTotalAmountCalculate(commissionCurrencies?.assets, 'commission');
		}
	}, [commissionCurrencies]);

	if (isLoading) {
		return <div className="assetDetailsTotalBalanceContainerLoading" />;
	}

	return (
		<div className="assetDetailsTotalBalanceOuterContainer">
			{isVerified ? (
				<div className="assetDetailsTotalBalanceContainer">
					<h1 className="assetDetailsTotalBalanceHeader">
						{t('asset_totalBalance')}
					</h1>
					<h1 className="assetDetailsTotalBalanceMain">
						{formatNumber(
							totalAmount,
							mainCurrencies?.assets.find(
								(assetPrecision) => assetPrecision.code === code
							)
								? mainCurrencies?.assets.find(
										(assetPrecision) => assetPrecision.code === code
								  )?.precision
								: basePrecision
						)}
						<h1 className="assetDetailsTotalBalanceMainCode">{code}</h1>
					</h1>
					{totalBaseAmount > 0 && (
						<h1 className="assetDetailsTotalBalanceBottom">
							{formatNumber(totalBaseAmount, basePrecision)}{' '}
							{globalAsset.code}
						</h1>
					)}
				</div>
			) : (
				<div className="assetDetailsTotalBalanceContainer">
					<h1 className="assetDetailsTotalBalanceHeader">
						{t('asset_totalBalance')}
					</h1>
					<h1 className="assetDetailsTotalBalanceMain">
						{formatNumber(0, 2)}
						<h1 className="assetDetailsTotalBalanceMainCode">{code}</h1>
					</h1>
					{totalBaseAmount > 0 && (
						<h1 className="assetDetailsTotalBalanceBottom">
							{formatNumber(0, 2)} {globalAsset.code}
						</h1>
					)}
				</div>
			)}
			{isVerified &&
			(isNonMainAccountsNotEmpty.margin ||
				isNonMainAccountsNotEmpty.spot ||
				isNonMainAccountsNotEmpty.commission) ? (
				<div className="assetDetailsTotalSplitContainer">
					{mainCurrencies?.assets?.find((asset) => asset.code === code) && (
						<>
							<div className="assetDetailTotalSplitItem">
								<h1 className="assetDetailTotalSplitItemHeader">
									{t('asset_availableOnWallet')}
								</h1>
								<h1 className="assetDetailTotalSplitItemAmount">
									{Number(
										mainCurrencies?.assets?.find(
											(asset) => asset.code === code
										)?.available
									) != 0
										? formatNumber(
												mainCurrencies?.assets?.find(
													(asset) => asset.code === code
												)?.available as number,
												mainCurrencies?.assets.find(
													(assetPrecision) =>
														assetPrecision.code === code
												)
													? mainCurrencies?.assets.find(
															(assetPrecision) =>
																assetPrecision.code ===
																code
													  )?.precision
													: basePrecision
										  )
										: 0}
									{' ' + code}
								</h1>
							</div>
							<div className="assetDetailsTotalSplitItemDivider" />
						</>
					)}
					{isNonMainAccountsNotEmpty.spot && (
						<div className="assetDetailTotalSplitItem">
							<h1 className="assetDetailTotalSplitItemHeader">
								{t('wallet_spot')}
							</h1>
							<h1 className="assetDetailTotalSplitItemAmount">
								{formatNumber(
									spotCurrencies?.assets?.find(
										(asset) => asset.code === code
									)?.available as number,
									spotCurrencies?.assets.find(
										(assetPrecision) => assetPrecision.code === code
									)
										? spotCurrencies?.assets.find(
												(assetPrecision) =>
													assetPrecision.code === code
										  )?.precision
										: basePrecision
								)}
								{' ' + code}
							</h1>
						</div>
					)}
					{isNonMainAccountsNotEmpty.margin && (
						<div className="assetDetailTotalSplitItem">
							<h1 className="assetDetailTotalSplitItemHeader">
								{t('wallet_leverage')}
							</h1>
							<h1 className="assetDetailTotalSplitItemAmount">
								{formatNumber(
									marginCurrencies?.assets?.find(
										(asset) => asset.code === code
									)?.available as number,
									marginCurrencies?.assets.find(
										(assetPrecision) => assetPrecision.code === code
									)
										? marginCurrencies?.assets.find(
												(assetPrecision) =>
													assetPrecision.code === code
										  )?.precision
										: basePrecision
								)}
								{' ' + code}
							</h1>
						</div>
					)}
					{isNonMainAccountsNotEmpty.commission && (
						<div className="assetDetailTotalSplitItem">
							<h1 className="assetDetailTotalSplitItemHeader">
								{t('wallet_commission')}
							</h1>
							<h1 className="assetDetailTotalSplitItemAmount">
								{formatNumber(
									commissionCurrencies?.assets?.find(
										(asset) => asset.code === code
									)?.available as number,
									commissionCurrencies?.assets.find(
										(assetPrecision) => assetPrecision.code === code
									)
										? commissionCurrencies?.assets.find(
												(assetPrecision) =>
													assetPrecision.code === code
										  )?.precision
										: basePrecision
								)}
								{' ' + code}
							</h1>
						</div>
					)}
					{(isNonMainAccountsNotEmpty.spot ||
						isNonMainAccountsNotEmpty.commission) && (
						<CustomButton
							onClick={handleAssetDetailsTransferModalOpen}
							text={t('wallet_transferToWallet') as string}
							className="marketAssetDetailsNavigationContainerItem"
							isSmall
							isGrey
						/>
					)}
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default AssetDetailsTotalCard;
