import { Helmet } from 'react-helmet-async';
import MyDocumentsLayout from '../../components/profile/documents';
import { TITLES } from '../../types/titles';

const ProfileMyDocuments = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.DOCUMENTS}</title>
			</Helmet>
			<MyDocumentsLayout />
		</>
	);
};

export default ProfileMyDocuments;
