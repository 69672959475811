import { MobileDatePicker } from '@mui/x-date-pickers';
import { ruRU, enUS } from '@mui/x-date-pickers/locales';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { CustomInput } from '../inputs';
import { useAppSelector } from '../../../hooks';
import { selectLocale, selectTheme } from '../../../services/root';
import { styled } from '@mui/material';
import { TStyledDatePickerProps } from '../../../types/styled/types';
import { TTheme } from '../../../types/types';

const mapDatePickerSxByType = ({
	isDarkMode,
}: // eslint-disable-next-line
TStyledDatePickerProps) => {
	const defaultSx = {
		'.MuiPaper-root': {
			borderRadius: '12px',
			backgroundColor: isDarkMode ? '#141414' : '#FFFFFF',
		},
		'.MuiTypography-root': {
			fontFamily: 'Noto Sans !important',
		},
		'.MuiButtonBase-root': {
			fontFamily: 'Noto Sans !important',
		},
		'.MuiTypography-overline': {
			fontFamily: 'Noto Sans !important',
			color: isDarkMode ? '#9C9C9D' : '#848790',
		},
		'.MuiPickersToolbar-content': {
			color: isDarkMode ? '#FEFEFE' : '#181A21',
			fontFamily: 'Noto Sans !important',
		},
		'.MuiPickersDay-root': {
			backgroundColor: '#00BEB4',
		},
	};
	return defaultSx;
};

const StyledMobileDatePicker = styled(MobileDatePicker, {
	shouldForwardProp: (prop) => prop !== 'isDarkMode',
})<TStyledDatePickerProps>(({ isDarkMode }) =>
	mapDatePickerSxByType({
		isDarkMode,
	})
);

interface CustomDatePickerProps {
	isFrom?: boolean;
	onChange: (e: Dayjs | null) => void;
	value: Dayjs | null;
	handleTodayClick?: () => void;
	label: string;
}

const CustomDatePicker = ({
	onChange,
	value,
	handleTodayClick,
	isFrom,
	label,
}: CustomDatePickerProps) => {
	const theme = useAppSelector(selectTheme);
	const locale = useAppSelector(selectLocale);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div
			style={{
				width: '100%',
			}}
		>
			<StyledMobileDatePicker
				isDarkMode={theme as TTheme}
				localeText={
					locale === 'ru'
						? ruRU.components.MuiLocalizationProvider.defaultProps.localeText
						: enUS.components.MuiLocalizationProvider.defaultProps.localeText
				}
				sx={{
					display: 'none !important',
				}}
				open={isOpen}
				value={value}
				onChange={(dateValue) => onChange(dateValue as Dayjs | null)}
				maxDate={dayjs()}
				onClose={() => setIsOpen(!isOpen)}
			/>
			<CustomInput
				isRequired={value ? true : false}
				type="text"
				id="selectDatePeriod"
				value={value?.format('DD MMM YYYY') as string}
				className=""
				onClick={() => setIsOpen(!isOpen)}
				isSelectDate
				label={label}
				isDateTo={!isFrom}
				handleTodayClick={handleTodayClick}
			/>
		</div>
	);
};

export default CustomDatePicker;
