import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import HistoryLayout from '../../components/history';
import { QueryClient } from '@tanstack/react-query';
import { fetchDictionary } from '../../api/common';
import { useAppDispatch } from '../../hooks';
import { useLoaderData } from 'react-router-dom';
import { setDictionary } from '../../services/dictionaries';
import { useEffect } from 'react';

export const historyRouteLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['historyLoader'],
		queryFn: () => fetchDictionary('periodfilters'),
		staleTime: 86400
	});
};

const History = () => {
	const dispatch = useAppDispatch();
	const data = useLoaderData();

	useEffect(() => {
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign(data as any, {
					dictionaryName: 'periodFilters'
				})
			)
		);
	}, []);
	
	return (
		<>
			<Helmet>
				<title>{TITLES.HISTORY.INDEX}</title>
			</Helmet>
			<HistoryLayout />
		</>
	);
};

export default History;
