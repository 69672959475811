import { Dispatch, SetStateAction } from 'react';
import { EllipseCheckBoxIcon } from '../../common/icons';
import { useTranslation } from 'react-i18next';

interface WalletConvertContainerProps {
	isLoading: boolean;
	setIsLowBalanceHidden: Dispatch<SetStateAction<boolean>>;
	isLowBalanceHidden: boolean;
	onWalletConvertModalClick: () => void;
	isVerified: boolean;
}

const WalletConvertContainer = ({
	isLoading,
	isLowBalanceHidden,
	setIsLowBalanceHidden,
	onWalletConvertModalClick,
	isVerified
}: WalletConvertContainerProps) => {
	const { t } = useTranslation();

	return (
		<>
			{isVerified && (
				<div className='hideBalanceContainer'>
					{isLoading ? (
						<div className='innerHideBalanceContainerLoading'></div>
					) : (
						<div className='innerHideBalanceContainer'>
							<EllipseCheckBoxIcon
								onClick={() =>
									setIsLowBalanceHidden(!isLowBalanceHidden)
								}
								className='hideLowBalanceIcon'
								isActive={isLowBalanceHidden}
							/>
							<p className='hideBalanceText'>
								{t('wallet_hideLowBalancesOr_small')}
								<span
									onClick={onWalletConvertModalClick}
									className='hideBalanceLinkText'
								>
									{' '}
									{t('wallet_convert')}
								</span>
							</p>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default WalletConvertContainer;
