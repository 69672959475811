import { Helmet } from 'react-helmet-async';
import MaintainLayout from '../../components/maintain';
import { TITLES } from '../../types/titles';

const Maintain = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.MAINTAIN.INDEX}</title>
			</Helmet>
			<MaintainLayout />
		</>
	);
};

export default Maintain;
