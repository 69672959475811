import { useMutation } from '@tanstack/react-query';
import {
	TBiometry,
	TCustomNavigate,
	TLoginBody,
	TRefreshToken
} from '../../../types/types';
import { ROUTES } from '../../../types/routes';
import {
	fetchLoginOTP,
	fetchLoginWithoutTwoFA,
	fetchLoginWithTwoFA,
	fetchRefreshToken
} from '../../../api/login';
import { AppDispatch } from '../../../store';

export function useLoginWithoutTwoFAMutation(navigate: TCustomNavigate) {
	const {
		mutateAsync: login,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['loginWithoutTwoFA'],
		mutationFn: (data: TLoginBody) =>
			fetchLoginWithoutTwoFA(data, navigate),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.additional.twoFAStatus != 'enabled') {
				navigate(ROUTES.AUTH.SETUP_TWOFA);
				return;
			}
			navigate(ROUTES.AUTH.INDEX);
		}
	});
	return {
		login,
		error,
		isError,
		isPending
	};
}

export function useRefreshToken(navigate: TCustomNavigate) {
	const {
		mutateAsync: refresh,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['refreshToken'],
		mutationFn: (data: TRefreshToken) => fetchRefreshToken(data),
		onSuccess: () => {
			navigate(ROUTES.WALLET.INDEX);
		},
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data.message === 'Token is not active') {
				navigate(ROUTES.AUTH.LOGIN);
			}
		}
	});
	return {
		refresh,
		error,
		isError,
		isPending
	};
}

export function useLoginWithTwoFAMutation(
	navigate: TCustomNavigate,
	biometry: TBiometry
) {
	const {
		mutateAsync: login,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['loginWithTwoFA'],
		mutationFn: (data: TLoginBody) => fetchLoginWithTwoFA(data, navigate),
		onSuccess: () => {
			if (biometry.isBiometricAvailable && !biometry.isBiometrySetup) {
				navigate(ROUTES.AUTH.SETUP_BIOMETRY);
				return;
			}
			navigate(ROUTES.WALLET.INDEX);
		}
	});
	return {
		login,
		error,
		isError,
		isPending
	};
}

export function useSendOtpCodeMutation(navigate: TCustomNavigate) {
	const {
		mutateAsync: sendOtpCode,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['sendOtpCode'],
		mutationFn: ({
			identityType,
			state,
			navigate,
			dispatch
		}: {
			identityType: string;
			state: string;
			navigate: TCustomNavigate;
			dispatch?: AppDispatch;
		}) => fetchLoginOTP(identityType, state, navigate, dispatch),
		onSuccess: async () => {
			navigate(ROUTES.AUTH.OTP);
		}
	});
	return {
		sendOtpCode,
		error,
		isError,
		isPending
	};
}
