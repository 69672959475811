import { useTranslation } from 'react-i18next';
import { mapClassnameForMobile } from '../../utils';
import { HeaderLayout } from '../common/header';
import { AboutIcons, RightChev } from '../common/icons';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectLocale, setLocale } from '../../services/root';
import { useState } from 'react';
import { LocaleModal } from '../common/modals';
import i18n from '../../locale/i18n';
import { CustomButton } from '../common/buttons';
import { ROUTES } from '../../types/routes';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';

const AboutLayout = () => {
	const dispatch = useAppDispatch();
	const locale = useAppSelector(selectLocale);

	const navigate = useCustomNavigate();

	const [isLocaleModalOpen, setIsLocaleModalOpen] = useState<boolean>(false);

	const { t } = useTranslation();

	const handleModalOpen = () => {
		setIsLocaleModalOpen(!isLocaleModalOpen);
	};

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.INDEX);
	};

	const handleLocaleItemClick = (i: string) => {
		setIsLocaleModalOpen(false);
		dispatch(setLocale(i));
		i18n.changeLanguage(i);
		navigate(ROUTES.ABOUT.INDEX, i);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout isAboutHeader isOnlyHeader={'about_title'} />
				<LocaleModal
					isModalOpen={isLocaleModalOpen}
					handleIsModalOpen={handleModalOpen}
					handleItemClick={handleLocaleItemClick}
				/>
				<div className={mapClassnameForMobile('walletLayout')}>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>
						<div className="commonContainer">
							<div
								onClick={() => navigate(ROUTES.ABOUT.HELP)}
								className="commonItem"
							>
								<AboutIcons.HelpIcon />
								<h1 className="commonText">
									{t('settings_helpAndSupport')}
								</h1>
								<RightChev />
							</div>
							<div onClick={handleModalOpen} className="commonItem">
								<AboutIcons.LanguageIcon />
								<h1 className="commonText">{t('general_language')}</h1>
								<h1 className="innerCommonText">
									{t(
										`general_${
											locale === 'en' ? 'english' : 'russian'
										}`
									)}
								</h1>
								<RightChev />
							</div>
							<div
								onClick={() => navigate(ROUTES.ABOUT.LEGAL_STATEMENTS)}
								className="commonItem"
							>
								<AboutIcons.LegalStatementsIcon />
								<h1 className="commonText">
									{t('settings_legalStatements')}
								</h1>
								<RightChev />
							</div>
							<div
								onClick={() => navigate(ROUTES.ABOUT.COMMUNITY)}
								className="commonItem"
							>
								<AboutIcons.CommunityIcon />
								<h1 className="commonText">{t('settings_community')}</h1>
								<RightChev />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutLayout;
