import { useRef } from 'react';

export const useHorizontalScroll = () => {
	const elementRef = useRef<HTMLDivElement>(null);

	const handleScrollTo = (event: React.MouseEvent) => {
		if(elementRef.current) {
			elementRef.current?.scrollTo({
				top: 0,
				left: event.pageX / 2,
				behavior: 'smooth'
			})
		}
	}

	return {
		elementRef,
		handleScrollTo
	};
};
