import { HeaderSearchLayout } from '../common/header';
import { useEffect, useState } from 'react';
import { useGetUserRests } from '../../data/mutations/wallet';
import { useGetMarketPrices } from '../../data/mutations/market';
import { useAppSelector } from '../../hooks';
import { selectIsVerified } from '../../services/user';
import { SearchItemList } from '../common/lists';

const SearchLayout = () => {
	const isVerified = useAppSelector(selectIsVerified);

	const [searchValue, setSearchValue] = useState<string>('');

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { isLoading: isUserRestsLoading } = useGetUserRests(isVerified);
	const { isLoading: isMarketPriceLoading } = useGetMarketPrices();

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleClearClick = () => {
		setSearchValue('');
	};

	useEffect(() => {
		if (!isMarketPriceLoading && !isUserRestsLoading) {
			setIsLoading(false);
		}
	}, [isUserRestsLoading, isMarketPriceLoading]);

	return (
		<div className={'component'}>
			<div className={'innerComponent searchComponent'}>
				<HeaderSearchLayout
					searchValue={searchValue}
					handleClearClick={handleClearClick}
					handleInputChange={handleInputChange}
				/>
				<div className="walletLayout">
					<SearchItemList searchValue={searchValue} isLoading={isLoading} />
				</div>
			</div>
		</div>
	);
};

export default SearchLayout;
