import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import MainLayout from '../../components/main';

const Main = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.MAIN.INDEX}</title>
			</Helmet>
			<MainLayout />
		</>
	);
};

export default Main;
