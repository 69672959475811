import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import AccountInfoLayout from '../../components/profile/account-info';

const ProfileAccountInfo = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.ACCOUNT_INFO}</title>
			</Helmet>
			<AccountInfoLayout />
		</>
	);
};

export default ProfileAccountInfo;
