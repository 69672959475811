interface CustomSwitchProps {
	onClick: () => void;
	isActive: boolean;
	setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomSwitch = ({ onClick, setIsActive, isActive }: CustomSwitchProps) => {
	const handleClick = () => {
		setIsActive(!isActive);
		onClick();
	};

	return (
		<div
			className={'switchContainer' + (isActive ? ' switchContainerActive' : '')}
			onClick={handleClick}
		>
			<div
				className={
					'innerContainerEllipse' +
					(isActive ? ' innerContainerEllipseActive' : '')
				}
			/>
		</div>
	);
};

export { CustomSwitch };
