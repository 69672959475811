import { Helmet } from 'react-helmet-async';
import LoginTwoFactorLayout from '../../components/auth/two-factor';
import { TITLES } from '../../types/titles';

const LoginTwoFactor = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.AUTH.TWOFA}</title>
			</Helmet>
			<LoginTwoFactorLayout />
		</>
	);
};

export default LoginTwoFactor;
