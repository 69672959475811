import { useTranslation } from 'react-i18next';
import {
	BackIcon,
	FavouriteIconAssetDetails,
	HistoryIcon,
	NotificationHeaderIcon,
	NotificationIcon,
	SearchBlackIcon,
	SearchIcon,
	StatementIcon,
	SupportIcon,
} from '../icons';
import { useLocation } from 'react-router-dom';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { memo, useState } from 'react';
import { mapTranslationKeyHeaderByName } from '../../../utils';
import { clearAmount } from '../../../services/market';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { CustomInput } from '../inputs';
import {
	selectPreviousLocation,
	selectPreviousMainLocation,
	setPreviousMainLocation,
} from '../../../services/root';
import { selectShortName } from '../../../services/user';
import { setHistoryOperationsList } from '../../../services/history';

interface HeaderProps {
	isAboutHeader?: boolean;
	isOnlyHeader?: string;
	isSupportIcon?: boolean;
	isFunctionalHeader?: boolean;
	isAuthorizedHeader?: boolean;
	handleBackClick?: () => void;
	//eslint-disable-next-line
	style?: any;
}

const HeaderLayout = memo(function HeaderLayout({
	isOnlyHeader,
	isSupportIcon,
	style,
	isAuthorizedHeader,
	isAboutHeader,
	isFunctionalHeader,
	handleBackClick,
}: HeaderProps) {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const handleSearchInputClick = () => {
		dispatch(setPreviousMainLocation(window.location.pathname));
		navigate(ROUTES.SEARCH.INDEX);
	};

	const handleProfileClick = () => {
		dispatch(setPreviousMainLocation(window.location.pathname));
		navigate(ROUTES.PROFILE.INDEX);
	};

	if (isSupportIcon) {
		return (
			<div className="outerHeaderLayout">
				<div style={style} className="headerLayout endAlignment">
					<div className="headerNavContainer">
						<SupportIcon
							onClick={() => navigate(ROUTES.ABOUT.INDEX)}
							className="headerNavIcon"
						/>
					</div>
				</div>
			</div>
		);
	}

	if (isAuthorizedHeader) {
		const shortName = useAppSelector(selectShortName);
		return (
			<div className="outerHeaderLayout">
				<div className="headerLayout">
					<div onClick={handleProfileClick} className="headerAccountContainer">
						<div className="headerAccountInnerContainer">
							<h1 className="headerAccountInnerContainerText">
								{shortName}
							</h1>
						</div>
					</div>
					<div
						className="headerSearchContainer"
						onClick={handleSearchInputClick}
					>
						<div className="headerSearchInnerContainer">
							<SearchIcon className="" />
							<h1 className="headerSearchInnerText">
								{t('general_searchTitle')}
							</h1>
						</div>
					</div>
					<div className="headerNotificationContainer">
						<NotificationHeaderIcon
							className="headerNotificationInnerContainer"
							onClick={() => console.log('WIP')}
						/>
					</div>
				</div>
			</div>
		);
	}

	if (isFunctionalHeader) {
		return (
			<div
				className={
					'headerAssetDetailsLayout' +
					(isAboutHeader ? ' headerGreyLayout' : '')
				}
			>
				<div
					className={
						'innerHeaderAssetDetailsLayout' +
						(isAboutHeader ? ' innerHeaderGreyLayout' : '')
					}
				>
					<BackIcon className="backIconContainer" onClick={handleBackClick} />
				</div>
			</div>
		);
	}

	return (
		<div className={isAboutHeader ? 'outerAboutHeaderLayout' : 'outerHeaderLayout'}>
			<div className={isAboutHeader ? 'aboutHeaderLayout' : 'headerLayout'}>
				<div className="headerLogoContainer">
					<h1 className="headerMainText">
						{t(
							isOnlyHeader
								? isOnlyHeader
								: mapTranslationKeyHeaderByName(
										location.pathname.split('/')[2]
								  )
						)}
					</h1>
				</div>
				{!isOnlyHeader && (
					<div className="headerNavContainer">
						<HistoryIcon className="headerNavIcon" />
					</div>
				)}
			</div>
		</div>
	);
});

interface HeaderHistoryLayoutProps {
	handleBackClick?: () => void;
	searchValue: string;
	handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleClearClick: () => void;
	handleStatementClick: () => void;
}

const HeaderHistoryLayout = ({
	handleBackClick,
	searchValue,
	handleClearClick,
	handleInputChange,
	handleStatementClick,
}: HeaderHistoryLayoutProps) => {
	const { t } = useTranslation();
	const [isActive, setIsActive] = useState<boolean>(false);

	const handleSearchClick = () => {
		setIsActive(!isActive);
	};

	if (isActive) {
		return (
			<div className={'headerSearchLayout headerHistorySearchLayout'}>
				<div className="innerHeaderSearchLayout innerHeaderHistorySearchLayout">
					<CustomInput
						placeHolder={t('general_searchTitle') as string}
						className={'modalSearchInput'}
						id={'registrationModalInput'}
						label=""
						value={searchValue}
						isRequired={false}
						onClearClick={handleClearClick}
						type={'text'}
						onChange={handleInputChange}
						isSearch
						autoFocus
					/>
					<h1 onClick={handleSearchClick} className="walletSearchModalText">
						{t('general_cancel')}
					</h1>
				</div>
			</div>
		);
	}

	return (
		<div className={'headerAssetDetailsLayout'}>
			<div className={'innerHeaderAssetDetailsLayout'}>
				<BackIcon className="backIconContainer" onClick={handleBackClick} />
				<div className="modalMarketAssetDetailsRightContainer">
					<SearchBlackIcon
						className="assetDetailsHeaderIconContainer"
						onClick={handleSearchClick}
					/>
					<StatementIcon
						className="assetDetailsHeaderIconContainer"
						onClick={handleStatementClick}
					/>
				</div>
			</div>
		</div>
	);
};

interface HeaderAssetDetailsLayoutProps {
	name: string;
	isFavourite: boolean;
	handleFavouriteClick: (isNeedToAdd: boolean) => void;
}

const HeaderAssetDetailsLayout = ({
	name,
	isFavourite,
	handleFavouriteClick,
}: HeaderAssetDetailsLayoutProps) => {
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const previousLocation = useAppSelector(selectPreviousLocation);

	const handleBackClick = () => {
		dispatch(
			setHistoryOperationsList({
				operations: [],
			})
		);
		dispatch(clearAmount());
		navigate(previousLocation != '' ? previousLocation : ROUTES.MARKET.INDEX);
	};

	return (
		<div className={'headerAssetDetailsLayout'}>
			<div className="innerHeaderAssetDetailsLayout">
				<BackIcon className="backIconContainer" onClick={handleBackClick} />
				<h1 className="modalMarketAssetDetailsHeaderText">{name}</h1>
				<div className="modalMarketAssetDetailsRightContainer">
					<NotificationIcon
						className="assetDetailsHeaderIconContainer"
						onClick={() => console.log('WIP')}
					/>
					<FavouriteIconAssetDetails
						className="assetDetailsHeaderIconContainer"
						onClick={() => handleFavouriteClick(!isFavourite)}
						isActive={isFavourite}
					/>
				</div>
			</div>
		</div>
	);
};

interface HeaderSearchLayoutProps {
	searchValue: string;
	handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleClearClick: () => void;
}

const HeaderSearchLayout = ({
	searchValue,
	handleInputChange,
	handleClearClick,
}: HeaderSearchLayoutProps) => {
	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	const previousLocation = useAppSelector(selectPreviousMainLocation);

	const handleBackClick = () => {
		navigate(previousLocation != '' ? previousLocation : ROUTES.WALLET.INDEX);
	};

	return (
		<div className={'headerSearchLayout'}>
			<div className="innerHeaderSearchLayout">
				<CustomInput
					placeHolder={t('general_searchTitle') as string}
					className={'modalSearchInput'}
					id={'registrationModalInput'}
					label=""
					value={searchValue}
					isRequired={false}
					onClearClick={handleClearClick}
					type={'text'}
					onChange={handleInputChange}
					isSearch
					autoFocus
				/>
				<h1 onClick={handleBackClick} className="walletSearchModalText">
					{t('general_cancel')}
				</h1>
			</div>
		</div>
	);
};

export {
	HeaderLayout,
	HeaderAssetDetailsLayout,
	HeaderSearchLayout,
	HeaderHistoryLayout,
};
