import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import ProfileLayout from '../../components/profile';

const Profile = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.INDEX}</title>
			</Helmet>
			<ProfileLayout />
		</>
	);
};

export default Profile;
