import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import AuthLayout from '../../components/auth';

const Auth = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.AUTH.INDEX}</title>
			</Helmet>
			<AuthLayout />
		</>
	);
};

export default Auth;
