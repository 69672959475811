import { HeaderLayout } from '../common/header';
import { BottomNavigationContainer } from '../common/navigation';
import WalletLayout from '../wallet';

const RootLayout = () => {
	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isAuthorizedHeader />
				<WalletLayout />
				<BottomNavigationContainer />
			</div>
		</div>
	);
};

export default RootLayout;
