import { Navigate, Outlet, useLoaderData } from 'react-router-dom';
import { ROUTES } from '../../types/routes';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	selectLocale,
	selectPreviousMainLocation,
	selectTheme,
	setAppSettings,
	setBiometry,
	setIsTelegram,
} from '../../services/root';
import { useTelegram } from '../../hooks/useTelegram';
import { useEffect } from 'react';
import { setDictionary } from '../../services/dictionaries';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { selectIsAuthenticated } from '../../services/auth';
import { TGlobalRouteLoaderResponse } from '../../types/types';
import { useGetUserRests } from '../../data/mutations/wallet';
import { selectIsVerified } from '../../services/user';

const ProtectedRoute = () => {
	const locale = useAppSelector(selectLocale);
	const isAuthenticated = useAppSelector(selectIsAuthenticated);

	if (!isAuthenticated) {
		return <Navigate to={ROUTES.AUTH.INDEX.replace(':locale', locale)} />;
	}

	return <Outlet />;
};

const CommonRoute = () => {
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const previousMainLocation = useAppSelector(selectPreviousMainLocation);
	const navigate = useCustomNavigate();

	useEffect(() => {
		if (isAuthenticated) {
			navigate(
				previousMainLocation != '' ? previousMainLocation : ROUTES.WALLET.INDEX
			);
		}
	}, []);

	return <Outlet />;
};

const GlobalRoute = () => {
	const theme = useAppSelector(selectTheme);
	const dispatch = useAppDispatch();
	const data = useLoaderData();
	const navigate = useCustomNavigate();

	const { isTelegram, biometricManager } = useTelegram();

	const handleIsInitBiometry = () => {
		biometricManager.init(() => {
			dispatch(
				setBiometry({
					isBiometricAvailable: biometricManager.isBiometricAvailable,
					isBiometryInited: true,
				})
			);
		});
	};

	useEffect(() => {
		if (theme === 'dark') {
			document.body.setAttribute('data-theme', theme?.toLowerCase());
		} else {
			document.body.removeAttribute('data-theme');
		}
	}, [theme]);

	useEffect(() => {
		if (isTelegram) {
			dispatch(setIsTelegram(isTelegram));
			handleIsInitBiometry();
		}

		dispatch(
			setDictionary(
				Object.assign((data as TGlobalRouteLoaderResponse)?.healthData, {
					dictionaryName: 'health',
				})
			)
		);

		if ((data as TGlobalRouteLoaderResponse)?.healthData?.title) {
			navigate(ROUTES.MAINTAIN.INDEX);
		}

		dispatch(
			setDictionary(
				Object.assign((data as TGlobalRouteLoaderResponse)?.assetIconsData, {
					dictionaryName: 'assetIcons',
				})
			)
		);

		dispatch(
			setDictionary(
				Object.assign(
					(data as TGlobalRouteLoaderResponse)?.depositWithdrawAssetsData,
					{
						dictionaryName: 'depositWithdrawAssets',
					}
				)
			)
		);

		dispatch(
			setDictionary(
				Object.assign((data as TGlobalRouteLoaderResponse)?.marketTabsData, {
					dictionaryName: 'marketTabs',
				})
			)
		);

		dispatch(
			setDictionary(
				Object.assign((data as TGlobalRouteLoaderResponse)?.registrationData, {
					dictionaryName: 'registration',
				})
			)
		);

		dispatch(setAppSettings((data as TGlobalRouteLoaderResponse)?.appSettingsData));
	}, []);

	return <Outlet />;
};

const ProfileRoute = () => {
	const dispatch = useAppDispatch();
	const data = useLoaderData();

	useEffect(() => {
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign(data as any, {
					dictionaryName: 'countries',
				})
			)
		);
	}, []);

	const isVerified = useAppSelector(selectIsVerified);
	//eslint-disable-next-line
	const {} = useGetUserRests(isVerified);

	return <Outlet />;
};

export { ProtectedRoute, CommonRoute, GlobalRoute, ProfileRoute };
