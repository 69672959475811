import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../common/buttons';
import { WhaleIcon } from '../../common/icons';

const WalletEmptyList = () => {
	const { t } = useTranslation();

	const handleSubmit = () => {
		console.log('WIP');
	};

	return (
		<div className='walletConfirmIdentityContainer'>
			<WhaleIcon className='walletConfirmIdentityWhale' />
			<h1 className='walletConfirmIdentityHeader'>
				{t('wallet_getYourFirstAsset')}
			</h1>
			<h1 className='walletConfirmIdentityBottom'>
				{t('wallet_youDontHaveAnyAssets')}
			</h1>
			<CustomButton
				className='walletConfirmIdentityButton'
				onClick={handleSubmit}
				text={t('wallet_makeFirstDeposit') as string}
			/>
		</div>
	);
};

export default WalletEmptyList;
