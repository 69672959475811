import React, { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { IconButton, InputAdornment, TextField, styled } from '@mui/material';
import { PinInputFieldProps } from 'react-pin-input-hook';
import { IMaskInput } from 'react-imask';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AssetIcon, CurrencyChev, VisibilityOffIcon, VisibilityOnIcon } from '../icons';
import { TStyledInputProps } from '../../../types/styled/types';
import { TExchangeItem, TTheme } from '../../../types/types';
import { CustomButton } from '../buttons';
import { formatNumber } from '../../../utils';
import { useAppSelector } from '../../../hooks';
import { selectTheme } from '../../../services/root';

interface PinInputProps {
	fields: PinInputFieldProps[];
	isError: boolean;
	isLoading?: boolean;
}

const mapInputSxByType = ({
	isDateOfBirth,
	isSelectCode,
	isSelectOtpCode,
	isError,
	isDarkMode,
	isSearch,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TStyledInputProps): any => {
	const defaultSx = {
		'& label.Mui-focused': {
			transform: 'translate(14px, 5px) scale(0.75)',
		},
		'& label.Mui-required': {
			transform: 'translate(14px, 5px) scale(0.75)',
		},
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: '#00BEB4 !important',
				borderWidth: '1px',
			},
			'&.Mui-disabled fieldset': {
				borderColor: '#F6F6F8 !important',
				borderWidth: '1px',
			},
			'&.Mui-disabled': {
				color: '#181A21',
			},
			'& input.Mui-disabled': {
				color: '#181A21',
			},
			'.MuiInputBase-input.Mui-disabled': {
				WebkitTextFillColor: '#848790',
				color: '#848790',
			},
			'& fieldset': {
				borderColor: isDarkMode === 'dark' ? '#1F1F1F' : '#F6F6F8',
			},
			'&:hover fieldset': {
				borderColor: isDarkMode === 'dark' ? '#1F1F1F' : '#F6F6F8',
			},
		},
		'.MuiInputBase-input.Mui-disabled': {
			WebkitTextFillColor: '#848790',
			color: '#848790',
		},
		fieldset: {
			legend: {
				maxWidth: '0.01px',
			},
		},
		borderRadius: '12px !important',
		background:
			isDarkMode === 'dark'
				? isError
					? '#332324'
					: '#1F1F1F'
				: isError
				? '#FDEDEB'
				: '#F6F6F8',
		borderColor: '#0159A2',
	};
	if (isDateOfBirth) {
		return Object.assign(defaultSx, {
			background: isDarkMode === 'dark' ? '#1F1F1F' : '#F6F6F8',
		});
	}
	if (isSelectCode) {
		return Object.assign(defaultSx, {
			'& input.Mui-disabled': {
				WebkitTextFillColor: '#181A21 !important',
			},
		});
	}
	if (isSelectOtpCode) {
		return Object.assign(defaultSx, {
			'& input.Mui-disabled': {
				WebkitTextFillColor: '#181A21 !important',
			},
		});
	}
	if (isSearch) {
		defaultSx.borderRadius = '99px !important';
		defaultSx.background = '#EBECEE';
		defaultSx['& .MuiOutlinedInput-root']['& fieldset'].borderColor =
			isDarkMode === 'dark' ? '#1F1F1F' : '#EBECEE';
		defaultSx['& .MuiOutlinedInput-root']['&:hover fieldset'].borderColor =
			isDarkMode === 'dark' ? '#1F1F1F' : '#EBECEE';
		return defaultSx;
	}
	return defaultSx;
};

const StyledInput = styled(TextField, {
	shouldForwardProp: (prop) =>
		prop !== 'isSelectCode' &&
		prop !== 'isSelectOtpCode' &&
		prop !== 'isError' &&
		prop !== 'isDarkMode' &&
		prop !== 'isSearch',
})<TStyledInputProps>(
	({ isDateOfBirth, isSelectCode, isSelectOtpCode, isError, isDarkMode, isSearch }) =>
		mapInputSxByType({
			isDateOfBirth,
			isSelectCode,
			isSelectOtpCode,
			isError,
			isDarkMode,
			isSearch,
		})
);

interface CustomInputProps {
	isRequired: boolean;
	type: string;
	id: string;
	label: string;
	value: string;
	onPaste?: (e: React.ClipboardEvent) => void;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onClearClick?: () => void;
	className: string;
	placeHolder?: string;
	isMobile?: boolean;
	isSelectCode?: boolean;
	imgUrl?: string;
	isPassword?: boolean;
	isDateOfBirth?: boolean;
	isDisabled?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	inputPropsRef?: MutableRefObject<any>;
	isError?: boolean;
	isNonCloseIcon?: boolean;
	isSelectOtpCode?: boolean;
	autoComplete?: string;
	isSearch?: boolean;
	isTabDisable?: boolean;
	autoFocus?: boolean;
	isStartIcon?: boolean;
	onClick?: () => void;
	handleTodayClick?: () => void;
	isDateTo?: boolean;
	isSelectDate?: boolean;
}

const CustomInput = ({
	isSearch,
	isRequired,
	type,
	id,
	label,
	value,
	onChange,
	className,
	onPaste,
	onClearClick,
	isError,
	placeHolder,
	isDisabled,
	inputPropsRef,
	isNonCloseIcon,
	isSelectCode,
	isMobile,
	imgUrl,
	isPassword,
	isDateOfBirth,
	isSelectOtpCode,
	autoComplete,
	isTabDisable,
	autoFocus,
	isStartIcon,
	onClick,
	handleTodayClick,
	isSelectDate,
	isDateTo,
}: CustomInputProps) => {
	const theme = useAppSelector(selectTheme);

	if (isSelectDate) {
		const { t } = useTranslation();

		return (
			<div className={className}>
				<StyledInput
					autoFocus={autoFocus}
					fullWidth
					isDarkMode={theme as TTheme}
					value={value}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
						},
					}}
					inputProps={{
						onClick: onClick,
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: value != '' ? '2px' : '16.5px',
							marginLeft: '0',
						},
						inputMode: 'decimal',
						returnkeytype: 'go',
					}}
					InputProps={{
						readOnly: true,
						style: {
							borderColor: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
							paddingLeft: '0',
						},
						endAdornment:
							isDateTo && handleTodayClick ? (
								<InputAdornment
									sx={{
										zIndex: '3',
									}}
									onClick={handleTodayClick}
									tabIndex={isTabDisable ? -1 : 1}
									position="start"
								>
									<h1
										style={{
											padding: '0 8px 10px 0',
										}}
										className="todayText"
									>
										{t('wallet_today')}
									</h1>
								</InputAdornment>
							) : (
								''
							),
					}}
					type={type}
					size="medium"
					id={id}
					label={label}
				/>
			</div>
		);
	}

	if (isSearch) {
		return (
			<div className={className}>
				<StyledInput
					autoFocus={autoFocus}
					isSearch
					disabled={isDisabled}
					fullWidth
					isDarkMode={theme as TTheme}
					isError={isError}
					placeholder={placeHolder}
					InputLabelProps={{
						sx: {
							display: 'none',
						},
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
						},
					}}
					inputProps={{
						autoComplete: 'off',
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontWeight: '400',
							fontSize: '16px !important',
							lineHeight: '24px',
							padding: '0',
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go',
					}}
					InputProps={{
						style: {
							borderColor: theme === 'dark' ? '#1F1F1F' : '#EBECEE',
							borderRadius: '99px',
							height: '42px',
						},
						startAdornment: (
							<InputAdornment
								tabIndex={isTabDisable ? -1 : 1}
								position="start"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M6.1001 11.6885C6.1001 8.60204 8.60216 6.09998 11.6886 6.09998C14.7751 6.09998 17.2771 8.60204 17.2771 11.6885C17.2771 14.775 14.7751 17.277 11.6886 17.277C8.60216 17.277 6.1001 14.775 6.1001 11.6885ZM11.6886 4.09998C7.4976 4.09998 4.1001 7.49747 4.1001 11.6885C4.1001 15.8795 7.4976 19.277 11.6886 19.277C13.322 19.277 14.8349 18.761 16.073 17.883L19.6221 21.4321C20.0126 21.8226 20.6458 21.8226 21.0363 21.4321C21.4269 21.0416 21.4269 20.4084 21.0363 20.0179L17.5396 16.5212C18.625 15.2086 19.2771 13.5247 19.2771 11.6885C19.2771 7.49747 15.8796 4.09998 11.6886 4.09998Z"
										fill="#181A21"
									/>
								</svg>
							</InputAdornment>
						),
						endAdornment: value?.length > 0 && (
							<InputAdornment
								tabIndex={isTabDisable ? -1 : 1}
								position="end"
							>
								<IconButton
									tabIndex={isTabDisable ? -1 : 1}
									onClick={onClearClick}
									edge="end"
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.0002 19.1667C8.31826 19.1667 5.3335 16.1819 5.3335 12.5C5.3335 8.8181 8.31826 5.83334 12.0002 5.83334C15.682 5.83334 18.6668 8.8181 18.6668 12.5C18.6668 16.1819 15.682 19.1667 12.0002 19.1667ZM12.0002 11.5572L10.5859 10.143C10.3256 9.88263 9.90349 9.88263 9.64314 10.143C9.38279 10.4033 9.38279 10.8254 9.64314 11.0858L11.0574 12.5L9.64315 13.9142C9.38279 14.1745 9.38279 14.5967 9.64314 14.857C9.90349 15.1174 10.3256 15.1174 10.5859 14.857L12.0002 13.4428L13.4144 14.857C13.6747 15.1173 14.0968 15.1173 14.3572 14.857C14.6175 14.5967 14.6175 14.1746 14.3572 13.9142L12.943 12.5L14.3572 11.0858C14.6175 10.8254 14.6175 10.4033 14.3572 10.143C14.0968 9.88263 13.6747 9.88263 13.4144 10.143L12.0002 11.5572Z"
											fill="#848790"
										/>
									</svg>
								</IconButton>
							</InputAdornment>
						),
					}}
					onPaste={onPaste}
					type={type}
					size="medium"
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isSelectOtpCode) {
		const { t } = useTranslation();
		return (
			<div className={className} onClick={onClearClick}>
				<StyledInput
					disabled={isDisabled}
					inputMode={'decimal'}
					fullWidth
					isDarkMode={theme as TTheme}
					isSelectOtpCode
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
						},
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: value != '' ? '2px' : '16.5px',
							marginLeft: '0',
						},
						inputMode: 'decimal',
						returnkeytype: 'go',
					}}
					InputProps={{
						readOnly: true,
						style: {
							borderColor: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
							paddingLeft: '0',
						},
						endAdornment:
							imgUrl === '' && value === t('registrationCodeCountry') ? (
								<InputAdornment
									tabIndex={isTabDisable ? -1 : 1}
									position="end"
								>
									<IconButton
										onClick={onClearClick}
										aria-label=""
										edge="end"
									>
										<ExpandMoreIcon className="countryCodeChev" />
									</IconButton>
								</InputAdornment>
							) : (
								''
							),
						startAdornment:
							imgUrl != '' ? (
								<InputAdornment
									tabIndex={isTabDisable ? -1 : 1}
									position="end"
								>
									<IconButton
										sx={{
											paddingBottom: '0px !important',
											paddingTop: '13px !important',
										}}
										onClick={onClearClick}
										aria-label=""
										edge="end"
									>
										<img
											className={'selectImg'}
											src={imgUrl}
											alt={value}
										/>
									</IconButton>
								</InputAdornment>
							) : (
								''
							),
					}}
					onClick={onClearClick}
					type={type}
					size="medium"
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isSelectCode) {
		const { t } = useTranslation();
		return (
			<div className={className} onClick={onClearClick}>
				<StyledInput
					fullWidth
					isDarkMode={theme as TTheme}
					isSelectCode
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
						},
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom:
								imgUrl != '' && value != t('registrationCodeCountry')
									? '2px'
									: '16.5px',
							marginLeft:
								imgUrl === '' && value === t('registrationCodeCountry')
									? '0'
									: '10px',
						},
						inputMode: 'decimal',
						returnkeytype: 'go',
					}}
					InputProps={{
						readOnly: true,
						style: {
							borderColor: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
							paddingLeft: '0',
						},
						endAdornment:
							imgUrl === '' || imgUrl === undefined ? (
								<InputAdornment
									tabIndex={isTabDisable ? -1 : 1}
									position="end"
								>
									<IconButton
										onClick={onClearClick}
										aria-label=""
										edge="end"
									>
										<ExpandMoreIcon className="countryCodeChev" />
									</IconButton>
								</InputAdornment>
							) : (
								''
							),
						startAdornment:
							imgUrl != '' && imgUrl != undefined ? (
								<InputAdornment
									tabIndex={isTabDisable ? -1 : 1}
									position="end"
								>
									<IconButton
										sx={{
											paddingBottom: '0px !important',
											paddingTop: '13px !important',
										}}
										onClick={onClearClick}
										aria-label=""
										edge="end"
									>
										<img
											className={'selectImg'}
											src={imgUrl}
											alt={value}
										/>
									</IconButton>
								</InputAdornment>
							) : (
								''
							),
					}}
					onClick={onClearClick}
					type={type}
					size="medium"
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isPassword) {
		const [showPassword, setShowPassword] = useState<boolean>(false);

		const handleClickShowPassword = () => setShowPassword((show) => !show);

		const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};

		return (
			<div className={className}>
				<StyledInput
					isError={isError}
					fullWidth
					isDarkMode={theme as TTheme}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
						},
					}}
					inputProps={{
						autoComplete: autoComplete,
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px',
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go',
					}}
					InputProps={{
						style: {
							borderColor: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
						},
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
								>
									{showPassword ? (
										<VisibilityOnIcon />
									) : (
										<VisibilityOffIcon />
									)}
								</IconButton>
							</InputAdornment>
						),
					}}
					type={showPassword ? 'text' : 'password'}
					size="medium"
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isMobile) {
		return (
			<div className={className}>
				<StyledInput
					disabled={isDisabled}
					fullWidth
					isDarkMode={theme as TTheme}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
						},
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px',
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go',
					}}
					InputProps={{
						style: {
							borderColor: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
						},
						inputComponent: PhoneInput,
					}}
					type={type}
					size="medium"
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isDateOfBirth) {
		return (
			<div className={className}>
				<StyledInput
					fullWidth
					isDateOfBirth
					isDarkMode={theme as TTheme}
					placeholder={'dd.mm.yyyy'}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
						},
					}}
					inputProps={{
						autoComplete: 'off',
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px',
						},
						returnkeytype: 'go',
					}}
					InputProps={{
						style: {
							borderColor: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
						},
						endAdornment: (
							<InputAdornment
								tabIndex={isTabDisable ? -1 : 1}
								position="end"
							>
								<IconButton
									tabIndex={isTabDisable ? -1 : 1}
									onClick={onClearClick}
									edge="end"
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.0002 19.1667C8.31826 19.1667 5.3335 16.1819 5.3335 12.5C5.3335 8.8181 8.31826 5.83334 12.0002 5.83334C15.682 5.83334 18.6668 8.8181 18.6668 12.5C18.6668 16.1819 15.682 19.1667 12.0002 19.1667ZM12.0002 11.5572L10.5859 10.143C10.3256 9.88263 9.90349 9.88263 9.64314 10.143C9.38279 10.4033 9.38279 10.8254 9.64314 11.0858L11.0574 12.5L9.64315 13.9142C9.38279 14.1745 9.38279 14.5967 9.64314 14.857C9.90349 15.1174 10.3256 15.1174 10.5859 14.857L12.0002 13.4428L13.4144 14.857C13.6747 15.1173 14.0968 15.1173 14.3572 14.857C14.6175 14.5967 14.6175 14.1746 14.3572 13.9142L12.943 12.5L14.3572 11.0858C14.6175 10.8254 14.6175 10.4033 14.3572 10.143C14.0968 9.88263 13.6747 9.88263 13.4144 10.143L12.0002 11.5572Z"
											fill="#848790"
										/>
									</svg>
								</IconButton>
							</InputAdornment>
						),
						inputComponent: isDateOfBirth ? DateOfBirthInput : undefined,
					}}
					autoComplete={'off'}
					type={type}
					size="medium"
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	return (
		<div className={className}>
			<StyledInput
				disabled={isDisabled}
				fullWidth
				isDarkMode={theme as TTheme}
				isError={isError}
				placeholder={placeHolder}
				InputLabelProps={{
					required: isRequired,
					style: {
						color: theme === 'dark' ? '#9C9C9D' : '#848790',
						fontFamily: 'Noto Sans',
						fontStyle: 'normal',
						fontSize: '16px !important',
						lineHeight: '24px',
					},
				}}
				inputProps={{
					autoComplete: type === 'email' ? 'none' : 'off',
					style: {
						color: theme === 'dark' ? '#FEFEFE' : '#181A21',
						fontFamily: 'Noto Sans',
						fontStyle: 'normal',
						fontSize: '16px !important',
						lineHeight: '24px',
						paddingBottom: '2px',
					},
					onKeyPress: (event) => {
						const { key } = event;
						if (key === 'Enter') {
							inputPropsRef?.current.focus();
						}
					},
					returnkeytype: 'go',
				}}
				InputProps={{
					style: {
						borderColor: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
						borderRadius: '12px',
						height: '60px',
					},
					startAdornment:
						isStartIcon && !imgUrl?.includes('undefined') ? (
							<InputAdornment
								tabIndex={isTabDisable ? -1 : 1}
								position="end"
							>
								<img className="userInfoFieldIcon" src={imgUrl} />
							</InputAdornment>
						) : (
							''
						),
					endAdornment: isNonCloseIcon ? (
						''
					) : (
						<InputAdornment tabIndex={isTabDisable ? -1 : 1} position="end">
							<IconButton
								tabIndex={isTabDisable ? -1 : 1}
								onClick={onClearClick}
								edge="end"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.0002 19.1667C8.31826 19.1667 5.3335 16.1819 5.3335 12.5C5.3335 8.8181 8.31826 5.83334 12.0002 5.83334C15.682 5.83334 18.6668 8.8181 18.6668 12.5C18.6668 16.1819 15.682 19.1667 12.0002 19.1667ZM12.0002 11.5572L10.5859 10.143C10.3256 9.88263 9.90349 9.88263 9.64314 10.143C9.38279 10.4033 9.38279 10.8254 9.64314 11.0858L11.0574 12.5L9.64315 13.9142C9.38279 14.1745 9.38279 14.5967 9.64314 14.857C9.90349 15.1174 10.3256 15.1174 10.5859 14.857L12.0002 13.4428L13.4144 14.857C13.6747 15.1173 14.0968 15.1173 14.3572 14.857C14.6175 14.5967 14.6175 14.1746 14.3572 13.9142L12.943 12.5L14.3572 11.0858C14.6175 10.8254 14.6175 10.4033 14.3572 10.143C14.0968 9.88263 13.6747 9.88263 13.4144 10.143L12.0002 11.5572Z"
										fill="#848790"
									/>
								</svg>
							</IconButton>
						</InputAdornment>
					),
				}}
				onPaste={onPaste}
				type={type}
				size="medium"
				id={id}
				label={label}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PhoneInput = React.forwardRef((props: any, ref: any) => {
	React.useImperativeHandle(ref, () => ({
		focus: () => {
			ref.current.focus();
		},
	}));

	const mapNumberMask = (value: string) => {
		if (value === '' || value === undefined) {
			return '000000000000000000';
		}
		if (value?.startsWith('7')) {
			return '0 000 000 000 000 000';
		} else if (value?.startsWith('375')) {
			return '000 00 000 00 00 00 00';
		}
	};

	return (
		<IMaskInput
			{...props}
			mask={mapNumberMask(props.value)}
			definitions={{
				'#': /[1-9]/,
			}}
			onAccept={props.onChange}
			overwrite={false}
		/>
	);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DateOfBirthInput = (props: any) => {
	return (
		<IMaskInput
			{...props}
			mask="00.00.0000"
			onAccept={props.onChange}
			definitions={{
				'#': /[1-9]/,
			}}
			overwrite={false}
		/>
	);
};

const PinInput = ({ fields, isError, isLoading }: PinInputProps) => {
	return (
		<div className={'pin_input'}>
			{fields.map((propsField, idx) => (
				<input
					key={idx}
					className={
						'pin_input__field' +
						(isError ? ' pin_input__field-error' : '') +
						(isLoading ? ` pin_input__field-loading-child` : '')
					}
					{...propsField}
				/>
			))}
		</div>
	);
};

interface ExchangeInputProps {
	amount: string;
	setAmount: (amount: string) => Promise<void>;
	available: number;
	asset: TExchangeItem;
	assets?: TExchangeItem[];
	handleItemClick?: (i: TExchangeItem) => void;
	handleAvailableClick?: (amount: string) => void;
	isFrom: boolean;
	isInputError: boolean;
	setIsInputError: React.Dispatch<React.SetStateAction<boolean>>;
	fontSize: string;
	rate?: string;
}

const ExchangeInput = ({
	amount,
	setAmount,
	available,
	asset,
	handleAvailableClick,
	isFrom,
	isInputError,
	setIsInputError,
	fontSize,
	rate,
}: ExchangeInputProps) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const inputRef = useRef<any>(null);
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const { t } = useTranslation();

	const handleFocus = () => {
		setIsFocused(!isFocused);
	};

	useEffect(() => {
		if (isFrom) {
			Number(amount) > available ? setIsInputError(true) : setIsInputError(false);
		}
	}, [amount]);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleAvailableContainerClick = () => {
		if (handleAvailableClick) handleAvailableClick(String(available));
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAmount(e.target.value);
	};

	return (
		<div
			className={
				'exchangeInputContainer' +
				(isFocused ? ' exchangeInputContainerFocused' : '')
			}
		>
			{/*<ExchangeModalSelect
				isFrom={isFrom}
				label={label}
				data={assets}
				isModalOpen={isModalOpen}
				handleIsModalOpen={handleModalOpen}
				handleItemClick={handleItemClick}
			/>*/}
			<div className={'inputHeader'}>
				<h1 className={'inputHeaderFirstElement'}>
					{t(isFrom ? 'exchange_pay' : 'exchange_receive')}
				</h1>
			</div>
			<div className={'inputMain'}>
				<input
					onKeyUp={(keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
						if (keyboardEvent.key === 'Enter') {
							keyboardEvent.preventDefault();
							if (inputRef.current) {
								inputRef.current.blur();
							}
						}
					}}
					inputMode="decimal"
					pattern={`^[0-9]*\\.?[0-9]{0,${asset.precision}}$`}
					placeholder={'0'}
					onFocus={handleFocus}
					onBlur={handleFocus}
					className={
						isInputError && isFrom ? 'exchangeInputError' : 'exchangeInput'
					}
					style={{
						fontSize: fontSize,
						padding: 0,
					}}
					type={'number'}
					onChange={onChange}
					value={amount}
				/>
				<div className={'inputMainAssetContainer'}>
					<CustomButton
						onClick={handleModalOpen}
						isSelectAsset={true}
						className={'inputMainAssetContainer'}
					>
						<AssetIcon
							assetName={asset?.code}
							group={asset?.group}
							className={'inputAssetIcon'}
						/>
						<h1 className={'inputAssetText'}>{asset.code}</h1>
						<CurrencyChev className="currencyTextChev" />
					</CustomButton>
				</div>
			</div>
			<div className={'inputBottom'}>
				{isFrom && (
					<div
						className={'inputHeaderAmountContainer'}
						onClick={handleAvailableContainerClick}
					>
						<h1 className={'inputHeaderSecondElement'}>
							{t('general_availableAmount')}:
						</h1>
						<h1 className={'inputHeaderThirdElement'}>
							{formatNumber(available)}
						</h1>
					</div>
				)}
				{!isFrom && (
					<div className={'inputHeaderAmountContainer'}>
						<h1 className={'inputHeaderSecondElement'}>{rate}</h1>
					</div>
				)}
				{isInputError && isFrom ? (
					<h1 className={'inputBottomErrorText'}>
						{t('exchange_insufficientFunds')}
					</h1>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export { CustomInput, PinInput, PhoneInput, ExchangeInput };
