import { QueryClient } from '@tanstack/react-query';
import { fetchHealth } from '../../api/common';

const globalRouteLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['globalLoader'],
		queryFn: () => fetchHealth(),
		staleTime: Infinity,
	});
};

export { globalRouteLoader };
