import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import {
	THistoryCurrentTab,
	THistoryDatePeriod,
	THistoryPeriod,
	THistoryTabs,
	TOperation,
	TOperationDetails,
	TOperationsByTabList,
} from '../../types/types';

export const selectIsHistoryPageInited = (state: TRootState) =>
	state.history.isHistoryPageInited;

export const selectHistoryTabs = (state: TRootState) => state.history.historyTabs;

export const selectHistoryCurrentTab = (state: TRootState) => state.history.currentTab;

export const selectHistoryOperationsList = (state: TRootState) =>
	state.history.historyOperationsList[
		state.history.currentTab?.server?.toLowerCase() as keyof TOperationsByTabList
	];

export const selectIsHistoryOperationsListEmpty = (state: TRootState) =>
	state.history.isHistoryOperationsListEmpty;

export const selectHistoryCurrentTimeFrame = (state: TRootState) =>
	state.history.currentTimeFrame;

export const selectCurrentOperation = (state: TRootState) =>
	state.history.currentOperation;

export const selectOperationDetails = (state: TRootState) =>
	state.history.operationDetails;

export const selectDatePeriod = (state: TRootState) => state.history.datePeriod;

interface IRootInitialState {
	isHistoryPageInited: boolean;
	historyTabs: THistoryTabs;
	currentTab: THistoryCurrentTab;
	historyOperationsList: TOperationsByTabList;
	isHistoryOperationsListEmpty: boolean;
	currentTimeFrame: THistoryPeriod | undefined;
	currentOperation: TOperation | undefined;
	operationDetails: TOperationDetails | undefined;
	datePeriod: THistoryDatePeriod;
}

const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

const initialState: IRootInitialState = {
	isHistoryPageInited: false,
	historyOperationsList: {
		all: [],
		deposit: [],
		withdraw: [],
		exchange: [],
	},
	historyTabs: {
		ru: ['Все', 'Депозит', 'Вывод', 'Обмен'],
		en: ['All', 'Deposit', 'Withdraw', 'Exchange'],
	},
	currentTab: {
		name: locale === 'en' ? 'All' : 'Все',
		server: 'all',
	},
	isHistoryOperationsListEmpty: true,
	currentTimeFrame: undefined,
	currentOperation: undefined,
	operationDetails: undefined,
	datePeriod: {
		dateFrom: '',
		dateTo: '',
		fullDateFrom: '',
		fullDateTo: '',
	},
};

export const historySlice = createSlice({
	name: 'history',
	initialState,
	reducers: {
		setIsHistoryPageInited(state, action) {
			state.isHistoryPageInited = action.payload;
		},
		setHistoryTab(state, action) {
			state.currentTab = action.payload;
		},
		setHistoryOperationsList(state, action) {
			if (action.payload?.messageText) {
				state.isHistoryOperationsListEmpty = true;
				return;
			}
			state.historyOperationsList[
				state.currentTab.server.toLowerCase() as keyof TOperationsByTabList
			] = action.payload?.operations;
			state.isHistoryOperationsListEmpty = action.payload?.operations?.length === 0;
		},
		setHistoryTimeFrame(state, action) {
			state.currentTimeFrame = action.payload;
		},
		setCurrentOperation(state, action) {
			state.currentOperation = action.payload;
		},
		setOperationDetails(state, action) {
			state.operationDetails = action.payload;
		},
		setDatePeriod(state, action) {
			state.datePeriod.dateFrom = action.payload.dateFrom;
			state.datePeriod.dateTo = action.payload.dateTo;
			state.datePeriod.fullDateFrom = action.payload.fullDateFrom;
			state.datePeriod.fullDateTo = action.payload.fullDateTo;
		},
		clearDatePeriod(state) {
			state.datePeriod.dateFrom = '';
			state.datePeriod.dateTo = '';
			state.datePeriod.fullDateFrom = '';
			state.datePeriod.fullDateTo = '';
		},
	},
});

export const {
	setIsHistoryPageInited,
	setHistoryTab,
	setHistoryOperationsList,
	setHistoryTimeFrame,
	setOperationDetails,
	setCurrentOperation,
	setDatePeriod,
	clearDatePeriod,
} = historySlice.actions;
