import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import AssetDetailsLayout from '../../components/market/asset-details';

const AssetDetails = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.MARKET.ASSET_DETAILS}</title>
			</Helmet>
			<AssetDetailsLayout />
		</>
	);
};

export default AssetDetails;
