import { customFetch } from '../../services';
import { AppDispatch } from '../../store';
import { API_ROUTES } from '../../types/api.routes';
import {
	THistoryDatePeriod,
	TRejectOperationBody,
	TSendStatementBody,
} from '../../types/types';
import { mapResponseError } from '../../utils';

export async function fetchOperationsList(
	accessToken: string,
	filter: string,
	dispatch: AppDispatch,
	period?: string,
	find?: string,
	assetCode?: string,
	datePeriod?: THistoryDatePeriod | undefined
) {
	let url = API_ROUTES.OPERATIONS.LIST.URL.replace('{filter}', filter);

	const paramObj = {};

	if (period) {
		Object.assign(paramObj, {
			datefilter: period,
		});
	}

	if (datePeriod) {
		Object.assign(paramObj, {
			datefrom: datePeriod.dateFrom,
			dateto: datePeriod.dateTo,
		});
	}

	if (find) {
		Object.assign(paramObj, {
			find: find,
		});
	}

	if (assetCode) {
		Object.assign(paramObj, {
			assetCode: assetCode,
		});
	}

	if (Object.keys(paramObj).length > 0) {
		url += `?${new URLSearchParams(paramObj)}`;
	}

	const defaultOperationsData = await customFetch(url, {
		method: API_ROUTES.OPERATIONS.LIST.METHOD,
		headers: { Authorization: `Bearer ${accessToken}` },
	});

	const jsonDefaultOperationsData = await defaultOperationsData.json();
	if (defaultOperationsData.status != 200) {
		mapResponseError(
			defaultOperationsData,
			jsonDefaultOperationsData,
			undefined,
			dispatch
		);
	}

	return jsonDefaultOperationsData;
}

export async function fetchOperationDetails(
	accessToken: string,
	operId: string,
	dispatch: AppDispatch
) {
	const operationDetailsData = await customFetch(
		API_ROUTES.OPERATIONS.DETAILS.URL.replace('{operId}', operId),
		{
			method: API_ROUTES.OPERATIONS.DETAILS.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonOperationDetailsData = await operationDetailsData.json();
	if (operationDetailsData.status != 200) {
		mapResponseError(
			operationDetailsData,
			jsonOperationDetailsData,
			undefined,
			dispatch
		);
	}

	return jsonOperationDetailsData;
}

export async function fetchRejectOperation({
	accessToken,
	operId,
	dispatch,
}: TRejectOperationBody) {
	const rejectOperationDetailsData = await customFetch(
		API_ROUTES.OPERATIONS.CANCEL.URL.replace('{operId}', operId),
		{
			method: API_ROUTES.OPERATIONS.CANCEL.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonRejectOperationDetailsData = await rejectOperationDetailsData.json();
	if (rejectOperationDetailsData.status != 200) {
		mapResponseError(
			rejectOperationDetailsData,
			jsonRejectOperationDetailsData,
			undefined,
			dispatch
		);
	}

	return jsonRejectOperationDetailsData;
}

export async function fetchSendStatement({
	accessToken,
	locale,
	period,
	dispatch,
	datePeriod,
}: TSendStatementBody) {
	let url = API_ROUTES.OPERATIONS.STATEMENT.URL.replace('{filter}', 'all');

	const paramObj = {};

	Object.assign(paramObj, {
		fileType: 'pdf',
		lang: locale,
	});

	if (period) {
		Object.assign(paramObj, {
			datefilter: period,
		});
	}

	if (datePeriod) {
		Object.assign(paramObj, {
			datefrom: datePeriod.dateFrom,
			dateto: datePeriod.dateTo,
		});
	}

	url += `?${new URLSearchParams(paramObj)}`;

	const sendGenerateStatementData = await customFetch(
		url,
		{
			method: API_ROUTES.OPERATIONS.STATEMENT.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		},
		true
	);

	const jsonSendGenerateStatementData = await sendGenerateStatementData.json();
	if (sendGenerateStatementData.status != 200) {
		mapResponseError(
			sendGenerateStatementData,
			jsonSendGenerateStatementData,
			undefined,
			dispatch
		);
	}

	return jsonSendGenerateStatementData;
}
