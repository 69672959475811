import { customFetch } from '../../services';
import { AppDispatch } from '../../store';
import { API_ROUTES } from '../../types/api.routes';
import { mapResponseError } from '../../utils';

export async function getMarketPrices(
	accessToken: string,
	globalAssetCode: string,
	filter: string,
	dispatch: AppDispatch
) {
	const marketPricesData = await customFetch(API_ROUTES.MARKET.PRICES.URL, {
		method: API_ROUTES.MARKET.PRICES.METHOD,
		headers: { Authorization: `Bearer ${accessToken}` },
		body: JSON.stringify({
			asset: globalAssetCode,
			filter: filter
		})
	});

	const jsonMarketPricesData = await marketPricesData.json();
	if (marketPricesData.status != 200) {
		mapResponseError(
			marketPricesData,
			jsonMarketPricesData,
			undefined,
			dispatch
		);
	}

	return jsonMarketPricesData;
}
