import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import AppSettingsLayout from '../../components/profile/settings';

const ProfileSettings = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SETTINGS}</title>
			</Helmet>
			<AppSettingsLayout />
		</>
	);
};

export default ProfileSettings;
