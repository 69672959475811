import { F2XMainIconGif } from '../common/icons';
import { MainNavigationContainer } from '../common/navigation';
import { useState } from 'react';
import { WalletItemList } from '../common/lists';
import { TWalletModalItem } from '../../types/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	selectGlobalAsset,
	selectIsBalanceHidden,
	selectIsEmpty,
	setGlobalAsset,
	setIsLeverageTransfer,
	setIsWalletPageInited,
	setTotalAmount,
} from '../../services/wallet';
import {
	WalletConvertModal,
	WalletModal,
	WalletTransferRestsToMainModal,
} from '../common/modals';
import { useGetUserRests } from '../../data/mutations/wallet';
import WalletTotalCard from './total-card';
import WalletConvertContainer from './search';
import { selectIsVerified } from '../../services/user';
import WalletConfirmIdentity from './confirm-identity';
import { setIsMarketPageInited } from '../../services/market';

const WalletLayout = () => {
	const dispatch = useAppDispatch();
	const globalAsset = useAppSelector(selectGlobalAsset);
	const isVerified = useAppSelector(selectIsVerified);
	const isEmpty = useAppSelector(selectIsEmpty);

	const { isLoading } = useGetUserRests(isVerified);

	const isBalanceHidden = useAppSelector(selectIsBalanceHidden);
	const [isLowBalanceHidden, setIsLowBalanceHidden] = useState<boolean>(false);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isWalletConvertModalOpen, setIsWalletConvertModalOpen] =
		useState<boolean>(false);

	const [
		isWalletTransferRestsToMainModalOpen,
		setIsWalletTransferRestsToMainModalOpen,
	] = useState<boolean>(false);

	const handleItemClick = (i: TWalletModalItem) => {
		dispatch(setIsWalletPageInited(false));
		dispatch(setTotalAmount(0));
		dispatch(setIsMarketPageInited(false));
		setIsModalOpen(!isModalOpen);
		dispatch(setGlobalAsset(i));
	};

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleWalletConvertModalOpen = () => {
		setIsWalletConvertModalOpen(!isWalletConvertModalOpen);
	};

	const handleWalletTransferRestsToMainModalOpen = (isLeverageTransfer?: boolean) => {
		dispatch(setIsLeverageTransfer(isLeverageTransfer as boolean));
		setIsWalletTransferRestsToMainModalOpen(!isWalletTransferRestsToMainModalOpen);
	};

	return (
		<div className="walletLayout">
			<WalletTransferRestsToMainModal
				globalAsset={globalAsset}
				isModalOpen={isWalletTransferRestsToMainModalOpen}
				handleIsModalOpen={handleWalletTransferRestsToMainModalOpen}
			/>
			<WalletConvertModal
				globalAsset={globalAsset}
				isModalOpen={isWalletConvertModalOpen}
				handleIsModalOpen={handleWalletConvertModalOpen}
			/>
			<WalletModal
				isModalOpen={isModalOpen}
				handleIsModalOpen={handleModalOpen}
				handleItemClick={handleItemClick}
				currentAsset={globalAsset}
			/>

			<WalletTotalCard
				isVerified={isVerified}
				handleModalOpen={handleModalOpen}
				isLoading={isLoading}
				isBalanceHidden={isBalanceHidden}
				globalAsset={globalAsset}
			/>

			<MainNavigationContainer
				isEmpty={isEmpty}
				isVerified={isVerified}
				isLoading={isLoading}
			/>

			<F2XMainIconGif />

			<WalletConvertContainer
				isVerified={isVerified}
				onWalletConvertModalClick={handleWalletConvertModalOpen}
				isLoading={isLoading}
				isLowBalanceHidden={isLowBalanceHidden}
				setIsLowBalanceHidden={setIsLowBalanceHidden}
			/>

			{isVerified ? (
				<WalletItemList
					handleWalletTransferRestsToMainModalOpen={
						handleWalletTransferRestsToMainModalOpen
					}
					isLoading={isLoading}
					isBalanceHidden={isBalanceHidden}
					globalAsset={globalAsset}
					isLowBalanceHidden={isLowBalanceHidden}
				/>
			) : (
				<WalletConfirmIdentity />
			)}
		</div>
	);
};

export default WalletLayout;
