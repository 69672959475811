import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import LoginSelectTwoFaLayout from '../../components/auth/select-two-fa';

const LoginSelectTwoFa = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.AUTH.SELECT_TWOFA}</title>
			</Helmet>
			<LoginSelectTwoFaLayout />
		</>
	);
};

export default LoginSelectTwoFa;
