import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectHistoryCurrentTab,
	selectHistoryCurrentTimeFrame,
	selectIsHistoryPageInited,
	setHistoryOperationsList,
	setIsHistoryPageInited,
	setOperationDetails,
} from '../../../services/history';
import { selectAccessToken } from '../../../services/auth';
import {
	fetchOperationDetails,
	fetchOperationsList,
	fetchRejectOperation,
} from '../../../api/history';
import {
	THistoryDatePeriod,
	THistoryPeriod,
	TRejectOperationBody,
} from '../../../types/types';
import { useMutation } from '@tanstack/react-query';

export const useGetDefaultHistory = () => {
	const dispatch = useAppDispatch();
	const isHistoryPageInited = useAppSelector(selectIsHistoryPageInited);
	const accessToken = useAppSelector(selectAccessToken);
	const currentTab = useAppSelector(selectHistoryCurrentTab);
	const historyTimeFrame = useAppSelector(selectHistoryCurrentTimeFrame);

	const [isLoading, setIsLoading] = useState<boolean>(
		isHistoryPageInited ? false : true
	);

	useEffect(() => {
		initMarketPage();
	}, []);

	const initMarketPage = async () => {
		if (!isHistoryPageInited) {
			dispatch(setIsHistoryPageInited(true));
		}
	};

	const getOperationsByTabChange = async (filter: string) => {
		setIsLoading(true);

		const historyOperationsData = await fetchOperationsList(
			accessToken as string,
			filter.toLowerCase(),
			dispatch,
			historyTimeFrame != undefined ? historyTimeFrame.datefilter : undefined
		);

		dispatch(setHistoryOperationsList(historyOperationsData));
		setIsLoading(false);
	};

	const getOperationsByPeriodChange = async (timeFrame: THistoryPeriod | undefined) => {
		setIsLoading(true);

		const historyOperationsData = await fetchOperationsList(
			accessToken as string,
			currentTab.server?.toLowerCase(),
			dispatch,
			timeFrame?.datefilter
		);

		dispatch(setHistoryOperationsList(historyOperationsData));

		setIsLoading(false);
	};

	const getOperationsByDatePeriod = async (datePeriod: THistoryDatePeriod) => {
		setIsLoading(true);

		const historyOperationsData = await fetchOperationsList(
			accessToken as string,
			currentTab.server?.toLowerCase(),
			dispatch,
			undefined,
			undefined,
			undefined,
			datePeriod
		);

		dispatch(setHistoryOperationsList(historyOperationsData));

		setIsLoading(false);
	};

	const getOperationsByFindChange = async (find: string | undefined) => {
		setIsLoading(true);

		const historyOperationsData = await fetchOperationsList(
			accessToken as string,
			currentTab.server?.toLowerCase(),
			dispatch,
			historyTimeFrame != undefined ? historyTimeFrame.datefilter : undefined,
			find
		);

		dispatch(setHistoryOperationsList(historyOperationsData));

		setIsLoading(false);
	};

	const getOperationsByAssetCode = async (assetCode: string) => {
		setIsLoading(true);

		const historyOperationsData = await fetchOperationsList(
			accessToken as string,
			currentTab.server?.toLowerCase(),
			dispatch,
			undefined,
			undefined,
			assetCode
		);

		dispatch(setHistoryOperationsList(historyOperationsData));

		setIsLoading(false);
	};

	const getOperationDetails = async (operId: string) => {
		const operationDetailsData = await fetchOperationDetails(
			accessToken as string,
			operId,
			dispatch
		);

		dispatch(setOperationDetails(operationDetailsData));
	};

	return {
		isLoading,
		getOperationsByTabChange,
		getOperationsByPeriodChange,
		getOperationsByFindChange,
		getOperationsByAssetCode,
		getOperationDetails,
		getOperationsByDatePeriod,
	};
};

export function useRejectOperation(handleCloseModal: () => void) {
	const {
		mutateAsync: reject,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['rejectOperation'],
		mutationFn: (data: TRejectOperationBody) => fetchRejectOperation(data),
		onSuccess: async () => {
			handleCloseModal();
		},
	});
	return {
		reject,
		error,
		isError,
		isPending,
	};
}
