import { useParams } from 'react-router-dom';
import { HeaderAssetDetailsLayout } from '../../common/header';
import { AssetIcon } from '../../common/icons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectMainAccountRests,
	selectSpotAccountsRests,
} from '../../../services/wallet';
import { useGetUserRests } from '../../../data/mutations/wallet';
import { selectIsVerified } from '../../../services/user';
import { selectMarketAssets, setFavouriteAssets } from '../../../services/market';
import { useGetMarketPrices } from '../../../data/mutations/market';
import AssetDetailsTotalCard from './total-card';
import { useEffect, useState } from 'react';
import { TMarketAsset, TOperation } from '../../../types/types';
import { AssetDetailsNavigationContainer } from '../../common/navigation';
import {
	AssetDetailsTransferModal,
	HistoryOperationDetailsModal,
	RejectOperationModal,
} from '../../common/modals';
import { useGetDefaultHistory } from '../../../data/mutations/history';
import AssetDetailsHistory from './history';
import { setCurrentOperation } from '../../../services/history';

const AssetDetailsLayout = () => {
	const { code } = useParams();
	const dispatch = useAppDispatch();

	const isVerified = useAppSelector(selectIsVerified);
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const marketAssets = useAppSelector(selectMarketAssets);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { isLoading: isUserRestsLoading } = useGetUserRests(isVerified);
	const { isLoading: isMarketPriceLoading } = useGetMarketPrices();
	const {
		isLoading: isHistoryLoading,
		getOperationsByAssetCode,
		getOperationDetails,
	} = useGetDefaultHistory();

	const [isAssetDetailsTransferModalOpen, setIsAssetDetailsTransferModalOpen] =
		useState<boolean>(false);

	const [operId, setOperId] = useState<string>('');

	const [asset, setAsset] = useState<TMarketAsset | undefined>(undefined);

	const [isHistoryOperationDetailsModalOpen, setIsHistoryOperationDetailsModalOpen] =
		useState<boolean>(false);

	const [isHistoryRejectModalOpen, setIsHistoryRejectModalOpen] =
		useState<boolean>(false);

	useEffect(() => {
		if (!isUserRestsLoading && !isMarketPriceLoading) {
			setIsLoading(false);
		}
	}, [isUserRestsLoading, isMarketPriceLoading]);

	useEffect(() => {
		if (marketAssets['all']) {
			setAsset(
				marketAssets['all']?.find((asset: TMarketAsset) => asset.code === code)
			);
		}
	}, [marketAssets]);

	useEffect(() => {
		getOperationsByAssetCode(code as string);
	}, []);

	const handleFavouriteClick = (isNeedToAdd: boolean) => {
		dispatch(setFavouriteAssets({ asset: asset, isNeedToAdd: isNeedToAdd }));
	};

	const handleAssetDetailsTransferModalOpen = () => {
		setIsAssetDetailsTransferModalOpen(!isAssetDetailsTransferModalOpen);
	};

	const handleHistoryOperationDetailsModalOpen = () => {
		setIsHistoryOperationDetailsModalOpen(!isHistoryOperationDetailsModalOpen);
	};

	const handleHistoryRejectModalOpen = (operId?: string) => {
		if (operId) {
			setOperId(operId);
		}
		handleHistoryOperationDetailsModalOpen();
		setIsHistoryRejectModalOpen(!isHistoryRejectModalOpen);
	};

	const handleItemClick = (i: TOperation) => {
		getOperationDetails(i.operaionid);
		dispatch(setCurrentOperation(i));
		setIsHistoryOperationDetailsModalOpen(!isHistoryOperationDetailsModalOpen);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HistoryOperationDetailsModal
					handleRejectOperation={handleHistoryRejectModalOpen}
					isModalOpen={isHistoryOperationDetailsModalOpen}
					handleIsModalOpen={handleHistoryOperationDetailsModalOpen}
				/>

				<RejectOperationModal
					handleIsModalOpen={handleHistoryRejectModalOpen}
					isModalOpen={isHistoryRejectModalOpen}
					operId={operId}
				/>

				<HeaderAssetDetailsLayout
					name={asset?.name as string}
					isFavourite={Boolean(
						marketAssets['crypto']?.some((asset) => asset.code === code)
					)}
					handleFavouriteClick={handleFavouriteClick}
				/>

				<AssetDetailsTransferModal
					handleIsModalOpen={handleAssetDetailsTransferModalOpen}
					isModalOpen={isAssetDetailsTransferModalOpen}
					assetCode={asset?.code as string}
				/>

				<div className="walletLayout">
					<AssetIcon
						isLoading={isLoading}
						assetName={code as string}
						group={asset?.group as string}
						className="marketAssetDetailsMainIcon"
						isCustomLoadingClassName="assetIconBigLoading"
					/>
					<AssetDetailsTotalCard
						handleAssetDetailsTransferModalOpen={
							handleAssetDetailsTransferModalOpen
						}
						isLoading={isLoading}
						code={code as string}
						isVerified={isVerified}
					/>
					{isVerified && (
						<>
							<AssetDetailsNavigationContainer
								mainRests={mainCurrencies?.assets}
								spotRests={spotCurrencies?.assets}
								code={code as string}
								isLoading={isLoading}
							/>
						</>
					)}

					<AssetDetailsHistory
						handleItemClick={handleItemClick}
						isLoading={isHistoryLoading}
					/>
				</div>
			</div>
		</div>
	);
};

export default AssetDetailsLayout;
