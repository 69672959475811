import { useTranslation } from 'react-i18next';
import { mapClassnameForMobile } from '../../../utils';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { GA_ROUTES, ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons';
import { SetupTwoFaIcons } from '../../common/icons';
import { useState } from 'react';

const SetupTwoFaLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [isPending, setIsPending] = useState<boolean>(false);

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.LOGIN);
	};

	const handleSubmit = () => {
		setIsPending(true);
		navigate(ROUTES.AUTH.SECRET_KEY);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div className={mapClassnameForMobile('walletLayout authMainContainer')}>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>
						<h1 className="confirmEmailText">
							{t('twoFactorAuthSettings_set2FA')}
						</h1>
						<h1 className="confirmCodeText">
							{t('twoFactorAuthSettings_install2FAApp')}
						</h1>
						<SetupTwoFaIcons.AppStoreIcon
							className=""
							onClick={() => window.open(GA_ROUTES.APPLE)}
						/>
						<SetupTwoFaIcons.GooglePlayIcon
							className=""
							onClick={() => window.open(GA_ROUTES.ANDROID)}
						/>
						<CustomButton
							isLoading={isPending}
							text={t('login_continue') as string}
							type={'submit'}
							onClick={handleSubmit}
							className={'startButton noMargin'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SetupTwoFaLayout;
