import { useEffect, useState } from 'react';
import { TMarketTab } from '../../types/types';
import { HeaderLayout } from '../common/header';
import {
	AssetsNavigationContainer,
	BottomNavigationContainer,
} from '../common/navigation';
import { useTranslation } from 'react-i18next';
import { AssetsChev } from '../common/icons';
import { MarketAssetsList } from '../common/lists';
import { useGetMarketPrices } from '../../data/mutations/market';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectMarketTabs } from '../../services/dictionaries';
import {
	selectCurrentTab,
	selectTimeFrame,
	setCurrentTab,
	setTimeFrame,
} from '../../services/market';
import { MarketTimeFrameModal } from '../common/modals';

const MarketLayout = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const marketTabs = useAppSelector(selectMarketTabs);
	const currentTab = useAppSelector(selectCurrentTab);
	const timeFrame = useAppSelector(selectTimeFrame);

	useEffect(() => {
		if (timeFrame === '') {
			dispatch(setTimeFrame(`24${t('filters_h')}`));
		}
		if (marketTabs?.dictionary && currentTab === '') {
			dispatch(
				setCurrentTab(
					marketTabs?.dictionary?.find((tab) => tab.filter === 'all')?.name
				)
			);
		}
	}, [marketTabs?.dictionary]);

	const [isAssetsSorted, setIsAssetsSorted] = useState<number>(-1);

	const { isLoading, getMarketPriceByTabChange } = useGetMarketPrices();

	const [isTimeFrameModalOpen, setIsTimeFrameModalOpen] = useState<boolean>(false);

	const handleNavigationTabChange = (tab: TMarketTab) => {
		setIsAssetsSorted(-1);
		dispatch(setCurrentTab(tab.name));
		getMarketPriceByTabChange(tab.filter);
	};

	const handleSortByPercentChange = () => {
		if (isAssetsSorted === -1) {
			setIsAssetsSorted(1);
			return;
		}
		if (isAssetsSorted === 1) {
			setIsAssetsSorted(0);
			return;
		}
		if (isAssetsSorted === 0) {
			setIsAssetsSorted(1);
			return;
		}
	};

	const handleTimeFrameClick = () => {
		setIsTimeFrameModalOpen(!isTimeFrameModalOpen);
	};

	const handleTimeFrameItemClick = (timeFrame: string) => {
		setIsTimeFrameModalOpen(!isTimeFrameModalOpen);
		dispatch(setTimeFrame(timeFrame));
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isAuthorizedHeader />
				<div className="walletLayout">
					<MarketTimeFrameModal
						currentTimeFrame={timeFrame}
						isModalOpen={isTimeFrameModalOpen}
						handleIsModalOpen={handleTimeFrameClick}
						handleItemClick={handleTimeFrameItemClick}
					/>

					<AssetsNavigationContainer
						currentTimeFrame={timeFrame}
						currentTab={currentTab}
						handleTabChange={handleNavigationTabChange}
						marketTabs={marketTabs.dictionary}
						isLoading={isLoading}
						handleTimeFrameClick={handleTimeFrameClick}
					/>

					<div className="upperAssetListContainer">
						<h1 className="upperAssetListItem">{t('assets_name')}</h1>
						<div
							onClick={handleSortByPercentChange}
							className="innerAssetListContainer"
						>
							<h1 className="upperAssetListItem">
								{t('assets_percentChange')}
							</h1>
							<AssetsChev isSorted={isAssetsSorted} className="" />
						</div>
					</div>

					<MarketAssetsList
						timeFrame={timeFrame}
						tab={
							marketTabs?.dictionary?.find(
								(tab) => tab.name === currentTab
							) as TMarketTab
						}
						isLoading={isLoading}
						isSorted={isAssetsSorted}
					/>
				</div>
				<BottomNavigationContainer />
			</div>
		</div>
	);
};

export default MarketLayout;
