import { customFetch } from "../../services";
import { API_ROUTES } from "../../types/api.routes";
import { mapResponseError } from "../../utils";

export async function fetchSecretKey(
) {
	const secretKeyData = await customFetch(API_ROUTES.SECURITY.NEWKEY.URL, {
		method: API_ROUTES.SECURITY.NEWKEY.METHOD,
		headers: {
            Authorization: `Bearer ${sessionStorage.getItem('temporaryAccessToken')}`
        },
	});

	const jsonSecretKeyData = await secretKeyData.json();
	if (secretKeyData.status != 200) {
		mapResponseError(secretKeyData, jsonSecretKeyData);
	}

	return jsonSecretKeyData;
}