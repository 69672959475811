import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectGlobalAsset } from '../../../services/wallet';
import { selectMarketTabs } from '../../../services/dictionaries';
import {
	selectIsMarketPageInited,
	selectMarketAssets,
	setInitMarketAssets,
	setIsMarketPageInited,
	setMarketAssets
} from '../../../services/market';
import { selectAccessToken } from '../../../services/auth';
import { getMarketPrices } from '../../../api/market';

export const useGetMarketPrices = () => {
	const dispatch = useAppDispatch();
	const isMarketPageInited = useAppSelector(selectIsMarketPageInited);
	const accessToken = useAppSelector(selectAccessToken);
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketTabs = useAppSelector(selectMarketTabs);

	const marketAssets = useAppSelector(selectMarketAssets);

	const [isLoading, setIsLoading] = useState<boolean>(
		isMarketPageInited ? false : true
	);

	useEffect(() => {
		if (marketTabs?.dictionary) {
			if (!isMarketPageInited) {
				dispatch(setInitMarketAssets(marketTabs?.dictionary));
			}
			initMarketPage();
		}
	}, [marketTabs]);

	const initMarketPage = async () => {
		if (!isMarketPageInited) {
			dispatch(setIsMarketPageInited(true));
			setIsLoading(true);

			const marketPricesData = await getMarketPrices(
				accessToken as string,
				globalAsset.code,
				marketTabs.dictionary?.find(
					(tab) => tab.filter.toLowerCase() === 'all'
				)?.filter as string,
				dispatch
			);

			dispatch(
				setMarketAssets(
					Object.assign(marketPricesData, { filter: 'all' })
				)
			);
			setIsLoading(false);
		}
	};

	const getMarketPriceByTabChange = async (filter: string) => {
		if (
			marketTabs.dictionary?.find((tab) => tab.filter === filter)?.type !=
				'favorites' &&
			marketAssets[filter]?.length === 0
		) {
			setIsLoading(true);
			const marketPricesData = await getMarketPrices(
				accessToken as string,
				globalAsset.code,
				filter,
				dispatch
			);

			dispatch(
				setMarketAssets(
					Object.assign(marketPricesData, { filter: filter })
				)
			);
			setIsLoading(false);
		}
	};

	return {
		isLoading,
		getMarketPriceByTabChange
	};
};
