import { useTranslation } from 'react-i18next';
import { TBiometricManager } from '../types/types';
import { useRefreshToken } from '../data/mutations/login/login';
import { useCustomNavigate } from './useCustomNavigate';
import { useAppDispatch, useAppSelector } from '.';
import { selectRefreshToken, setRefreshToken } from '../services/auth';
import { ROUTES } from '../types/routes';

export const useBiometry = (biometricManager: TBiometricManager) => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const refreshToken = useAppSelector(selectRefreshToken);
	const dispatch = useAppDispatch();

	const { isPending, refresh } = useRefreshToken(navigate);

	const handleSkipClick = () => {
		dispatch(setRefreshToken(''));
		navigate(ROUTES.WALLET.INDEX);
	};

	const handleRefreshToken = async () => {
		biometricManager.requestAccess(
			{
				reason: t('passcodeSetup_youCanUsePasscodeAndBiometry')
			},
			async (isAccessGranted) => {
				if (isAccessGranted) {
					biometricManager.authenticate(
						{
							reason: t(
								'passcodeSetup_youCanUsePasscodeAndBiometry'
							)
						},
						async (isAuthenticated, token) => {
							if (isAuthenticated) {
								refresh({
									refreshToken: token,
									dispatch: dispatch,
									biometricManager: biometricManager
								});
							}
						}
					);
				}
			}
		);
	};

	const handleCreateBiometry = async () => {
		biometricManager.requestAccess(
			{
				reason: t('passcodeSetup_youCanUsePasscodeAndBiometry')
			},
			async (isAccessGranted) => {
				if (isAccessGranted) {
					biometricManager.authenticate(
						{
							reason: t(
								'passcodeSetup_youCanUsePasscodeAndBiometry'
							)
						},
						async (isAuthenticated) => {
							if (isAuthenticated) {
								refresh({
									refreshToken: refreshToken,
									dispatch: dispatch,
									biometricManager: biometricManager
								});
							}
						}
					);
				}
			}
		);
	};

	return {
		handleCreateBiometry,
		isPending,
		handleSkipClick,
		handleRefreshToken
	};
};
