import { useTranslation } from 'react-i18next';
import { mapClassnameForMobile } from '../../../utils';
import { CustomButton } from '../../common/buttons';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import QRCode from 'qrcode';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectQrCodePath,
	selectSecretKey,
	setIsEnablingTwoFa,
} from '../../../services/auth';
import { CopyIcon } from '../../common/icons';
import { useEffect, useState } from 'react';

const SecretKeyLayout = () => {
	const { t } = useTranslation();
	const secretKey = useAppSelector(selectSecretKey);
	const qrCodePath = useAppSelector(selectQrCodePath);
	const dispatch = useAppDispatch();
	const [qrCodeImage, setQrCodeImage] = useState<string>('');

	const [isDeviceMobile, setIsDeviceMobile] = useState<boolean>(false);

	useEffect(() => {
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/iPhone/i)
		) {
			setIsDeviceMobile(true);
		}
	}, []);

	useEffect(() => {
		QRCode.toDataURL(
			qrCodePath,
			{
				width: 250,
			},
			(err, url) => {
				setQrCodeImage(url);
			}
		);
	}, [secretKey, qrCodePath]);

	const navigate = useCustomNavigate();

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.SETUP_TWOFA);
	};

	const handleSubmit = () => {
		dispatch(setIsEnablingTwoFa(true));
		navigate(ROUTES.AUTH.TWO_FA);
	};

	const handleCopyClick = () => {
		navigator.clipboard.writeText(secretKey);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div className={mapClassnameForMobile('walletLayout authMainContainer')}>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>
						<h1 className="confirmEmailText">
							{t('twoFactorAuthSettings_set2FA')}
						</h1>
						<h1 className="confirmCodeText">
							{t('twoFactorAuthSettings_copySecretCode')}
						</h1>

						{isDeviceMobile ? (
							<div className="secretKeyContainer">
								<h1 className="secretKeyText">
									{t('twoFactorAuthSettings_secretCode')}
								</h1>
								<div className="secretKeyInnerContainer">
									<h1 className="secretKeyInnerText">{secretKey}</h1>
									<CopyIcon className="" onClick={handleCopyClick} />
								</div>
							</div>
						) : (
							<div className="secretKeyQrContainer">
								<img src={qrCodeImage} alt={qrCodeImage} />
							</div>
						)}

						<CustomButton
							text={t('login_continue') as string}
							type={'submit'}
							onClick={handleSubmit}
							className={'startButton noMargin'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SecretKeyLayout;
