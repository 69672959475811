interface APIErrorProps {
	isError: boolean;
	//eslint-disable-next-line
	error: any;
}

const APIError = ({ isError, error }: APIErrorProps) => {
	return <>{isError && <h1 className={'loginErrorText'}>{error?.message}</h1>}</>;
};

export { APIError };
