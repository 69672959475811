import InActiveLayout from '../components/inactive';

const InActive = () => {
	return (
		<>
			<InActiveLayout />
		</>
	);
};

export default InActive;
