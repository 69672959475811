import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import RootLayout from '../../components/root';

const Wallet = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.ROOT.INDEX}</title>
			</Helmet>
			<RootLayout />
		</>
	);
};

export default Wallet;
