import { useTranslation } from 'react-i18next';
import { WhaleMaintainIcon } from '../common/icons';
import { CustomButton } from '../common/buttons';
import { useAppSelector } from '../../hooks';
import { selectHealthDictionary } from '../../services/dictionaries';

const MaintainLayout = () => {
	const { t } = useTranslation();
	const healthDictionary = useAppSelector(selectHealthDictionary);

	const handleRefreshClick = () => {
		history.back();
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div className="walletLayout">
					<WhaleMaintainIcon className="walletConfirmIdentityWhale" />
					<h1 className="walletConfirmIdentityHeader">
						{t('maintenance_title')}
					</h1>
					<h1 className="walletConfirmIdentityBottom">
						{t('maintenance_subtitle')}
					</h1>
					<CustomButton
						onClick={handleRefreshClick}
						className="walletConfirmIdentityButton"
						text={
							healthDictionary &&
							healthDictionary?.dictionary?.url &&
							healthDictionary?.dictionary?.captionUrl
								? healthDictionary?.dictionary?.captionUrl
								: (t('maintenance_refresh') as string)
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default MaintainLayout;
