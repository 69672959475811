import { useNavigate } from 'react-router-dom';

export const useCustomNavigate = () => {
	const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";
	const navigate = useNavigate();

	return function (path: string, localLocale?: string) {
		navigate(path.replace(':locale', localLocale ? localLocale : locale) + window.location.search);
	};
};
