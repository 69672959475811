import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { CustomInput } from '../common/inputs';
import { CustomCheckbox } from '../common/checkboxes';

const InActiveLayout = () => {
	const [isActive, setIsActive] = useState<boolean>(false);

	const [counter, setCounter] = useState<number>(60);

	const [activityType, setActivityType] = useState<string>('');

	const [email, setEmail] = useState<string>('');
	const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);

	const [isChecked, setIsChecked] = useState<boolean[]>([false]);
	const [inActiveDate, setInActiveDate] = useState<string>('');

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setEmail(e.target.value);
		e.target.value.length > 0 ? setIsEmailRequired(true) : setIsEmailRequired(false);
	};

	const buttonRef = useRef(null);

	useEffect(() => {
		if (counter == 0) {
			setIsActive(false);
			setCounter(60);
			setInActiveDate(new Date().toISOString());
		}
		const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter, isActive]);

	const handleEmailClearClick = () => {
		setEmail('');
		setIsEmailRequired(false);
	};

	useEffect(() => {
		//eslint-disable-next-line
		function handleUserInteraction(event: any) {
			setIsActive(true);
			setCounter(counter);
			if (event instanceof PointerEvent) {
				setActivityType(
					'lastActivity : click : x: ' + event?.pageX + ', y:' + event?.pageY
				);
				return;
			}
			if (event instanceof KeyboardEvent) {
				setActivityType('lastActivity : keyup : key: ' + event?.key);
				return;
			}
			if (event instanceof MouseEvent) {
				setActivityType(
					'lastActivity : mouseMove : x: ' +
						event?.screenX +
						' , y: ' +
						event?.screenY
				);
				return;
			}
		}

		window.addEventListener('keyup', handleUserInteraction);
		window.addEventListener('mousemove', handleUserInteraction);
		window.addEventListener('click', handleUserInteraction);
		return () => {
			window.removeEventListener('keyup', handleUserInteraction);
			window.removeEventListener('mousemove', handleUserInteraction);
			window.removeEventListener('click', handleUserInteraction);
		};
	}, []);

	return (
		<>
			{isActive ? (
				<>
					<h1>isActive ? {String(isActive)}</h1>
					<h1>{activityType}</h1>
					<h1>timeLeft for Inactivity ? {counter}</h1>
					<CustomInput
						isError={false}
						isRequired={isEmailRequired}
						type={'email'}
						id={'email'}
						label={'Email'}
						value={email}
						onChange={handleEmailChange}
						className={'emailInput'}
						onClearClick={handleEmailClearClick}
						inputPropsRef={buttonRef}
					/>
					<CustomCheckbox
						id={'1'}
						isError={false}
						key={'1'}
						checked={isChecked[0]}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setIsChecked([e.target.checked])
						}
					/>
				</>
			) : (
				<>
					<h1>you are afk(inactive)</h1>
					<h1>you were afk(inactive) in :{inActiveDate}</h1>
				</>
			)}
		</>
	);
};

export default InActiveLayout;
