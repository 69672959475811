import { useTranslation } from 'react-i18next';
import { AssetIcon } from '../../../common/icons';
import { HistoryItemList } from '../../../common/lists';
import { TOperation } from '../../../../types/types';

interface Props {
	isLoading: boolean;
	handleItemClick: (i: TOperation) => void;
}

const AssetDetailsHistory = ({ isLoading, handleItemClick }: Props) => {
	const { t } = useTranslation();

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	return (
		<div className='marketAssetDetailsHistoryContainer'>
			<h1 className='marketAssetDetailsHistoryContainerHeader'>
				{t('history_title')}
			</h1>
			{isLoading && (
				<div className='marketAssetDetailsHistoryInnerNavigationContainer'>
					<div className='marketAssetDetailsHistoryInnerNavigationContainerItemLoading' />
				</div>
			)}
			<div className='marketAssetDetailsHistoryInnerContainer'>
				{isLoading ? (
					loadingCards.map((value) => (
						<div
							key={value}
							className='marketAssetDetailsHistoryInnerItem'
						>
							<AssetIcon
								isLoading={isLoading}
								className='assetIconLoading'
								group=''
								assetName=''
							/>
							<div className='marketAssetDetailsHistoryInnerContainerInfo'>
								<div className='marketAssetDetailsHistoryInnerContainerInfoHeaderLoading' />
								<div className='marketAssetDetailsHistoryInnerContainerInfoBottomLoading' />
							</div>
						</div>
					))
				) : (
					<HistoryItemList
						handleItemClick={handleItemClick}
						isLoading={isLoading}
					/>
				)}
			</div>
		</div>
	);
};

export default AssetDetailsHistory;
