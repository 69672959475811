import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import SecretKeyLayout from '../../components/auth/secret-key';
import { QueryClient } from '@tanstack/react-query';
import { fetchSecretKey } from '../../api/security';
import { useLoaderData } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { useEffect } from 'react';
import { setSecretKey } from '../../services/auth';

export const secretKeyLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['secretKeyLoader'],
		queryFn: () => fetchSecretKey(),
		staleTime: Infinity
	});
};

const LoginSecretKey = () => {
	const data = useLoaderData();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			setSecretKey({
				//eslint-disable-next-line
				secretKey: (data as any)?.key,
				//eslint-disable-next-line
				qrCodePath: (data as any)?.uri
			})
		);
	}, []);

	return (
		<>
			<Helmet>
				<title>{TITLES.AUTH.SECRET_KEY}</title>
			</Helmet>
			<SecretKeyLayout />
		</>
	);
};

export default LoginSecretKey;
