import { useEffect, useState } from 'react';
import {
	fetchConvertLowBalances,
	fetchTransferToMainWallet,
	fetchUserMarginRests,
	fetchUserRestByAccount,
	fetchUserRests,
} from '../../../api/wallet';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { selectAccessToken } from '../../../services/auth';
import { selectAppSettings } from '../../../services/root';
import {
	clearRests,
	selectGlobalAsset,
	selectIsWalletPageInited,
	setCommissionRests,
	setIsWalletPageInited,
	setMainRests,
	setMarginRests,
	setSpotRests,
} from '../../../services/wallet';
import {
	TAccountInfo,
	TAsset,
	TConvertLowBalancesBody,
	TMarginAccountResponse,
	TTransferToMainWallet,
} from '../../../types/types';
import { useMutation } from '@tanstack/react-query';
import { fetchUserInfo, fetchVerificationInfo } from '../../../api/user';

export function useTransferToMainRestsMutation(
	setIsSummary?: React.Dispatch<React.SetStateAction<boolean>>,
	handleIsModalOpen?: () => void
) {
	const {
		mutateAsync: transferToMainRests,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['transferToMainRests'],
		mutationFn: (data: TTransferToMainWallet) => fetchTransferToMainWallet(data),
		onSuccess: () => {
			if (setIsSummary) {
				setIsSummary(true);
			}
			if (handleIsModalOpen) {
				handleIsModalOpen();
			}
		},
	});
	return {
		transferToMainRests,
		error,
		isError,
		isPending,
	};
}

export function useConvertLowBalancesMutation(
	setIsSummary: React.Dispatch<React.SetStateAction<boolean>>
) {
	const {
		mutateAsync: convertLowBalances,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['convertLowBalances'],
		mutationFn: (data: TConvertLowBalancesBody) => fetchConvertLowBalances(data),
		onSuccess: () => {
			setIsSummary(true);
		},
	});
	return {
		convertLowBalances,
		error,
		isError,
		isPending,
	};
}

export const useGetUserRests = (isVerified: boolean) => {
	const accessToken = useAppSelector(selectAccessToken);
	const appSettings = useAppSelector(selectAppSettings);
	const globalAsset = useAppSelector(selectGlobalAsset);
	const isWalletPageInited = useAppSelector(selectIsWalletPageInited);

	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = useState<boolean>(
		isWalletPageInited ? false : true
	);

	useEffect(() => {
		if (appSettings) {
			initWalletPage();
			dispatch(setIsWalletPageInited(true));
		}
	}, [appSettings, globalAsset, isWalletPageInited]);

	const initWalletPage = async () => {
		if (!isWalletPageInited) {
			await getUserInfo();
			await getUserRests();
		}
	};

	const getUserInfo = async () => {
		await fetchUserInfo(dispatch, accessToken as string);
		if (!isVerified) {
			await fetchVerificationInfo({
				accessToken: accessToken as string,
				dispatch: dispatch,
			});
		}
	};

	const getUserRests = async () => {
		if (isVerified) {
			dispatch(clearRests());
			setIsLoading(true);
			let isAtleastOneMarginAccount = false;
			const accountsInfo = await fetchUserRests(dispatch, accessToken as string);

			accountsInfo?.accounts.forEach(async (account: TAccountInfo) => {
				if (
					appSettings?.tradingLeverageCodes.includes(account.tradingProductCode)
				) {
					isAtleastOneMarginAccount = true;
				}

				if (
					account.accTypeName === 'Main' ||
					account.accTypeName === 'Commission' ||
					appSettings?.tradingSpotCodes.includes(account.tradingProductCode)
				) {
					const data = await fetchUserRestByAccount({
						navigate: navigate,
						accountNumber: account.accNum,
						assetCode: globalAsset?.code,
						accessToken: accessToken as string,
					});
					const rest = {
						accNum: data?.accounts[0]?.accNum,
						isEmpty: data?.accounts[0]?.assets.length === 0,
						basePrecision: data?.basePrecission,
						baseLowBalance: data?.baseLowBalance,
						assets: data?.accounts[0]?.assets,
					};
					if (account.accTypeName === 'Main') {
						dispatch(setMainRests(rest));
					} else if (account.accTypeName === 'Commission') {
						dispatch(setCommissionRests(rest));
					} else {
						dispatch(setSpotRests(rest));
					}
				}
			});
			if (isAtleastOneMarginAccount) {
				const data = await fetchUserMarginRests({
					navigate: navigate,
					assetCode: globalAsset?.code,
					accessToken: accessToken as string,
				});
				const localAccountRest: {
					basePrecision: number;
					baseLowBalance: number;
					assets: TAsset[] | undefined;
					isEmpty: boolean;
				} = {
					isEmpty: true,
					basePrecision: data?.basePrecission,
					baseLowBalance: data?.baseLowBalance,
					assets: undefined,
				};

				data?.accounts.forEach((marginAccount: TMarginAccountResponse) => {
					if (localAccountRest.assets) {
						let isFound = false;
						localAccountRest?.assets?.forEach((localRestAsset: TAsset) => {
							if (localRestAsset.code === marginAccount.balanceAsset.code) {
								localRestAsset.available += marginAccount.balance;
								localRestAsset.baseAvailable += marginAccount.baseBalance;
								localRestAsset.baseAvailable00h +=
									marginAccount.baseBalance00h;
								localRestAsset.baseReserved += 0;
								localRestAsset.baseReserved00h += 0;
								if (
									localRestAsset.baseEquity &&
									localRestAsset.baseEquity00h
								) {
									localRestAsset.baseEquity += marginAccount.baseEquity;
									localRestAsset.baseEquity00h +=
										marginAccount.baseBalance00h;
								}

								isFound = true;
								return;
							}
						});
						if (!isFound) {
							localAccountRest.assets?.push({
								baseReserved00h: 0,
								baseReserved: 0,
								available: marginAccount.balance,
								baseAvailable: marginAccount.baseBalance,
								baseAvailable00h: marginAccount.baseBalance00h,
								baseEquity: marginAccount.baseEquity,
								baseEquity00h: marginAccount.baseEquity00h,
								...marginAccount.balanceAsset,
								reserved: 0,
							});
						}
					} else {
						localAccountRest.assets = [
							{
								baseReserved00h: 0,
								baseReserved: 0,
								available: marginAccount.balance,
								baseAvailable: marginAccount.baseBalance,
								baseAvailable00h: marginAccount.baseBalance00h,
								baseEquity: marginAccount.baseEquity,
								baseEquity00h: marginAccount.baseEquity00h,
								...marginAccount.balanceAsset,
								reserved: 0,
							},
						];
					}
					if (marginAccount.balance > 0) {
						localAccountRest.isEmpty = false;
					}
				});
				dispatch(setMarginRests(localAccountRest));
			}
			setIsLoading(false);
		}
	};

	return {
		isLoading,
	};
};
