import { THistoryPeriod } from '../../../../types/types';
import { OperationStatusIcon, RightChev } from '../../icons';

interface CountryModalItemProps {
	item: {
		code: string;
		name: string;
		link: string;
		phoneCode: string;
		isRestricted: boolean;
	};
	handleItemClick: (i: { code: string; imgUrl: string }) => void;
	handleModalOpen: () => void;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

const CountryModalItem = ({
	item,
	handleItemClick,
	handleModalOpen,
	setSearchValue,
}: CountryModalItemProps) => {
	const handleClick = () => {
		handleItemClick({
			code: item.code,
			imgUrl: item.link,
		});
		handleModalOpen();
		setSearchValue('');
	};
	return (
		<div onClick={handleClick} className={'modalItem'}>
			<div key={item.code} className={'modalItemLogo'}>
				<img
					key={item.code}
					className={'modalItemImg'}
					src={item.link}
					alt={item.code}
				/>
				<h1 className={'modalItemLogoText'}>{item.name}</h1>
			</div>
		</div>
	);
};

interface OTPModalItemProps {
	item: {
		key: string;
		value: string;
	};
	handleItemClick: (i: { key: string; value: string }) => void;
	handleModalOpen: () => void;
}

const OTPModalItem = ({ item, handleItemClick, handleModalOpen }: OTPModalItemProps) => {
	const handleClick = () => {
		handleItemClick({
			key: item.key,
			value: item.value,
		});
		handleModalOpen();
	};
	return (
		<div onClick={handleClick} className={'modalItem'}>
			<h1 className={'modalOtpItemCode'}>{item.key}</h1>
		</div>
	);
};

interface HistoryPeriodModalItemProps {
	item?: THistoryPeriod;
	handleItemClick?: (i: THistoryPeriod) => void;
	name?: string;
	idx: number;
	isCustom?: boolean;
	handleCustomPeriodModalOpen?: () => void;
	isActive?: boolean;
	datePeriod?: string;
}

const HistoryPeriodModalItem = ({
	item,
	handleItemClick,
	idx,
	isCustom,
	name,
	handleCustomPeriodModalOpen,
	isActive,
	datePeriod,
}: HistoryPeriodModalItemProps) => {
	if (isCustom) {
		return (
			<div
				key={idx}
				onClick={handleCustomPeriodModalOpen && handleCustomPeriodModalOpen}
				className="commonItem"
			>
				<h1 style={{
					width: 'auto'
				}} className="commonText">{name}</h1>
				<h1 className="innerCommonText">{datePeriod}</h1>
				<RightChev />
			</div>
		);
	}

	return (
		<div
			key={idx}
			onClick={() => handleItemClick && handleItemClick(item as THistoryPeriod)}
			className="commonItem"
		>
			<h1 className="commonText">{item?.name}</h1>
			{isActive && <OperationStatusIcon status="done" />}
		</div>
	);
};

export { CountryModalItem, OTPModalItem, HistoryPeriodModalItem };
