import { Helmet } from 'react-helmet-async';
import SearchLayout from '../../components/search';
import { TITLES } from '../../types/titles';

const Search = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.SEARCH.INDEX}</title>
			</Helmet>
			<SearchLayout />
		</>
	);
};

export default Search;
