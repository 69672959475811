import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../common/buttons';
import { BiometryIcon } from '../../common/icons';
import { useTelegram } from '../../../hooks/useTelegram';
import { useBiometry } from '../../../hooks/useBiometry';

const SetupBiometryLayout = () => {
	const { biometricManager } = useTelegram();
	const { t } = useTranslation();
	const { handleCreateBiometry, handleSkipClick, isPending } =
		useBiometry(biometricManager);

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div
					style={{
						zIndex: '1',
					}}
					className="walletLayout authMainContainer"
				>
					<div className="biometryInnerContainer">
						<BiometryIcon
							className=""
							biometryType={biometricManager.biometricType}
						/>
						<h1 className="biometryInnerHeaderText">
							{t(
								biometricManager.biometricType === 'face'
									? 'system_weUseFaceId'
									: 'system_weUseTouchId'
							)}
						</h1>
						<h1 className="biometryInnerBottomText">
							{t(
								biometricManager.biometricType === 'face'
									? 'passcodeSetup_youCanUsePasscodeAndBiometry'
									: 'passcodeSetup_youCanUsePasscodeAndBiometry'
							)}
						</h1>
					</div>
					<div className="biometryBottomContainer">
						<CustomButton
							isLoading={isPending}
							onClick={handleCreateBiometry}
							className="startButton noMargin"
							text={t('passcodeSetup_create') as string}
						/>
						<CustomButton
							onClick={handleSkipClick}
							className="startButton noMargin"
							isGrey
							text={t('passcodeSetup_skip') as string}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SetupBiometryLayout;
