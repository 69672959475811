export const API_ROUTES = {
	LOGIN: {
		METHOD: 'POST',
		URL: '/api/token/login',
	},
	REFRESH_TOKEN: {
		METHOD: 'GET',
		URL: '/api/token/refresh',
	},
	SEND_OTP: {
		METHOD: 'POST',
		URL: '/api/token/login/twofa/otp/send',
	},
	REVOKE: {
		METHOD: 'DELETE',
		URL: '/api/token/revoke',
	},
	DICTIONARY: {
		METHOD: 'GET',
		URL: '/api/dictionaries',
	},
	HEALTH: {
		METHOD: 'GET',
		URL: '/api/health',
	},
	SECURITY: {
		NEWKEY: {
			METHOD: 'GET',
			URL: '/api/security/twofa/googleauthenticator/newkey',
		},
		ENABLE_GA: {
			METHOD: 'POST',
			URL: '/api/security/twofa/googleauthenticator/enable',
		},
	},
	MARKET: {
		PRICES: {
			METHOD: 'POST',
			URL: '/api/assets/prices',
		},
	},
	OPERATIONS: {
		CANCEL: {
			METHOD: 'GET',
			URL: '/api/operations/cancel/{operId}',
		},
		DETAILS: {
			METHOD: 'GET',
			URL: '/api/operations/details/{operId}',
		},
		LIST: {
			METHOD: 'GET',
			URL: '/api/operations/list/{filter}'
		},
		STATEMENT: {
			METHOD: 'GET',
			URL: '/api/operations/list/{filter}/statement'
		}
	},
	USER: {
		VERIFICATION: {
			INFO: {
				METHOD: 'GET',
				URL: '/api/users/verification/info',
			},
		},
		INFO: {
			METHOD: 'GET',
			URL: '/api/users/profile/info',
		},
		GENERATE_ACCOUNT_CONFIRMATION_LETTER: {
			METHOD: 'POST',
			URL: '/api/users/profile/accountLetter',
		},
	},
	WALLET: {
		TRANSFER_RESTS_TO_MAIN_WALLET: {
			METHOD: 'POST',
			URL: '/api/operations/transfer/batch/toacc',
		},
		ACCOUNTS_INFO: {
			METHOD: 'GET',
			URL: '/api/accounts/info',
		},
		ACCOUNT_REST: {
			METHOD: 'GET',
			URL: '/api/accounts/rests',
		},
		ACCOUNTS_MARGIN_RESTS: {
			METHOD: 'GET',
			URL: '/api/accounts/rests/margin',
		},
		CONVERT_LOW_BALANCES: {
			METHOD: 'POST',
			URL: '/api/operations/exchange/convertlow',
		},
	},
};
