import { useEffect } from 'react';
import { MainLogoIcon } from '../common/icons';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { ROUTES } from '../../types/routes';

const MainLayout = () => {
	const navigate = useCustomNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate(ROUTES.AUTH.INDEX);
		}, 1000);
	}, []);

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div
					style={{
						zIndex: '1'
					}}
					className='walletLayout authMainContainer'
				>
					<MainLogoIcon />
				</div>
			</div>
		</div>
	);
};

export default MainLayout;
