import { memo, MutableRefObject } from 'react';
import { Button, CircularProgress, styled } from '@mui/material';
import { West } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TStyledButtonProps } from '../../../types/styled/types';
import { TTheme } from '../../../types/types';
import { useAppSelector } from '../../../hooks';
import { selectTheme } from '../../../services/root';

const mapButtonSxByType = ({
	isExchangeBackButton,
	isClose,
	isSelectAsset,
	isText,
	isBack,
	isShowMore,
	isSwapButton,
	isGrey,
	isDarkMode,
	isSmall
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TStyledButtonProps): any => {
	const defaultSx = {
		textTransform: 'unset !important',
		fontFamily: 'Noto Sans !important',
		fontWeight: '400 !important',
		fontSize: '16px !important',
		fontStyle: 'normal !important',
		lineHeight: '24px !important',
		height: isSmall ? '42px !important' : '56px !important',
		borderRadius: '12px !important'
	};
	if (isExchangeBackButton) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background: '#EBECEE !important'
			},
			background:
				isDarkMode === 'dark'
					? '#303030 !important'
					: '#EBECEE !important',
			color:
				isDarkMode === 'dark'
					? '#FEFEFE !important'
					: '#181A21 !important'
		});
	}
	if (isClose) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background: '#F6F6F8 !important'
			},
			background:
				isDarkMode === 'dark'
					? '#1F1F1F !important'
					: '#F6F6F8 !important',
			borderRadius: '50% !important',
			minWidth: '42px !important',
			width: '42px !important',
			height: '42px !important',
			padding: '1px 1px 1px 1px !important'
		});
	}
	if (isSwapButton) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background:
					isDarkMode === 'dark'
						? '#303030 !important'
						: '#EBECEE !important'
			},
			'&:hover': {
				background: '#00BEB4'
			},
			'&:active': {
				background: '#00BEB4'
			},
			'&:focus': {
				background: '#00BEB4'
			},
			background: '#00BEB4',
			borderRadius: '50% !important',
			minWidth: '48px !important',
			width: '48px !important',
			height: '48px !important',
			padding: '1px 1px 1px 1px !important'
		});
	}
	if (isSelectAsset) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background: '#EBECEE !important'
			},
			background:
				isDarkMode === 'dark'
					? '#1F1F1F !important'
					: '#F6F6F8 !important',
			color:
				isDarkMode === 'dark'
					? '#FEFEFE !important'
					: '#848790 !important',
			fontSize: '14px !important',
			lineHeight: '20px !important',
			height: '36px !important',
			padding: '1px 3px 1px 3px !important'
		});
	}
	if (isShowMore) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background:
					isDarkMode === 'dark'
						? '#9C9C9D !important'
						: '#EBECEE !important'
			},
			background:
				isDarkMode === 'dark'
					? '#1F1F1F !important'
					: '#F6F6F8 !important',
			color:
				isDarkMode === 'dark'
					? '#9C9C9D !important'
					: '#848790 !important',
			fontSize: '14px !important',
			lineHeight: '20px !important',
			height: '46px !important'
		});
	}
	if (isText) {
		return Object.assign(defaultSx, {
			fontWeight: '600 !important',
			height: '60px !important',
			textAlign: 'center',
			color: '#00BEB4'
		});
	}
	if (isBack) {
		return Object.assign(defaultSx, {
			textAlign: 'center',
			color: '#00BEB4',
			height: '40px !important',
			justifyContent: 'flex-start'
		});
	}
	if (isGrey) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background: '#EBECEE !important',
				color: '#181A21 !important'
			},
			'&:hover': {
				background: '#EBECEE'
			},
			'&:active': {
				background: '#EBECEE'
			},
			'&:focus': {
				background: '#EBECEE'
			},
			background: '#EBECEE',
			color: '#181A21 !important'
		});
	}
	return Object.assign(defaultSx, {
		'&.Mui-disabled': {
			background:
				isDarkMode === 'dark'
					? '#303030 !important'
					: '#EBECEE !important',
			color:
				isDarkMode === 'dark'
					? '#5F5F5F !important'
					: '#BFC1C5 !important'
		},
		'&:hover': {
			background: '#00BEB4'
		},
		'&:active': {
			background: '#00BEB4'
		},
		'&:focus': {
			background: '#00BEB4'
		},
		background: '#00BEB4',
		color: '#FFF !important'
	});
};

const StyledButton = styled(Button, {
	shouldForwardProp: (prop) =>
		prop !== 'isExchangeBackButton' &&
		prop !== 'isClose' &&
		prop !== 'isSelectAsset' &&
		prop !== 'isBack' &&
		prop !== 'isShowMore' &&
		prop !== 'isSwapButton' &&
		prop !== 'isGrey' &&
		prop !== 'isDarkMode' &&
		prop !== 'isSmall'
})<TStyledButtonProps>(
	({
		isExchangeBackButton,
		isClose,
		isSelectAsset,
		isBack,
		isText,
		isShowMore,
		isSwapButton,
		isGrey,
		isDarkMode,
		isSmall
	}) =>
		mapButtonSxByType({
			isExchangeBackButton,
			isClose,
			isSelectAsset,
			isText,
			isBack,
			isShowMore,
			isSwapButton,
			isGrey,
			isDarkMode,
			isSmall
		})
);

interface Props {
	isLoading?: boolean;
	isDisabled?: boolean;
	text?: string;
	type?: 'button' | 'submit' | 'reset';
	onClick: () => void;
	className: string;
	isBack?: boolean;
	isGrey?: boolean;
	isText?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	buttonRef?: MutableRefObject<any>;
	isShowMore?: boolean;
	isExpanded?: boolean;
	isSelectAsset?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children?: any;
	isSwapButton?: boolean;
	isClose?: boolean;
	isExchangeBackButton?: boolean;
	isSmall?: boolean;
}
const CustomButton = memo(function CustomButton({
	isLoading,
	isDisabled,
	text,
	type,
	onClick,
	className,
	isBack,
	isText,
	buttonRef,
	isShowMore,
	isExpanded,
	isSelectAsset,
	children,
	isSwapButton,
	isClose,
	isExchangeBackButton,
	isGrey,
	isSmall
}: Props) {
	const theme = useAppSelector(selectTheme);
	const { t } = useTranslation();

	if (isExchangeBackButton) {
		return (
			<div className={className}>
				<StyledButton
					isExchangeBackButton
					isDarkMode={theme as TTheme}
					fullWidth
					ref={buttonRef}
					disableElevation
					type='submit'
					variant={'contained'}
					disabled={isDisabled}
					onClick={onClick}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isGrey) {
		return (
			<div className={className}>
				<StyledButton
					ref={buttonRef}
					isDarkMode={theme as TTheme}
					fullWidth
					isGrey
					isSmall={isSmall}
					endIcon={
						isLoading ? (
							<CircularProgress
								sx={{
									'.MuiCircularProgress-circle': {
										stroke: '#FFFFFF'
									}
								}}
								thickness={5}
								size={15}
							/>
						) : (
							''
						)
					}
					disableElevation
					type={type}
					variant={'contained'}
					disabled={isDisabled}
					onClick={onClick}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isClose) {
		return (
			<div className={className}>
				<StyledButton
					isClose
					isDarkMode={theme as TTheme}
					fullWidth
					TouchRippleProps={{
						classes: {
							child: 'analyticsShowMoreButtonLayout'
						}
					}}
					className={''}
					disabled={isDisabled}
					ref={buttonRef}
					disableElevation
					type='submit'
					variant={'contained'}
					onClick={onClick}
				>
					{children}
				</StyledButton>
			</div>
		);
	}

	if (isSwapButton) {
		return (
			<div className={className}>
				<StyledButton
					isSwapButton
					isDarkMode={theme as TTheme}
					fullWidth
					TouchRippleProps={{
						classes: {
							child: 'analyticsShowMoreButtonLayout'
						}
					}}
					className={''}
					disabled={isDisabled}
					ref={buttonRef}
					disableElevation
					type='submit'
					variant={'contained'}
					onClick={onClick}
				>
					{children}
				</StyledButton>
			</div>
		);
	}

	if (isSelectAsset) {
		return (
			<div className={className}>
				<StyledButton
					isSelectAsset
					isDarkMode={theme as TTheme}
					fullWidth
					TouchRippleProps={{
						classes: {
							child: 'analyticsShowMoreButtonLayout'
						}
					}}
					className={className}
					ref={buttonRef}
					disableElevation
					type='submit'
					variant={'contained'}
					onClick={onClick}
				>
					{children}
				</StyledButton>
			</div>
		);
	}

	if (isShowMore) {
		return (
			<div className={className}>
				<StyledButton
					isShowMore
					isDarkMode={theme as TTheme}
					fullWidth
					TouchRippleProps={{
						classes: {
							child: 'analyticsShowMoreButtonLayout'
						}
					}}
					ref={buttonRef}
					disableElevation
					type='submit'
					variant={'contained'}
					onClick={onClick}
					endIcon={
						isExpanded ? (
							<KeyboardArrowDownIcon
								sx={{
									color: '#848790'
								}}
							/>
						) : (
							<KeyboardArrowUpIcon
								sx={{
									color: '#848790'
								}}
							/>
						)
					}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isText) {
		return (
			<div className={className}>
				<StyledButton
					isText
					isDarkMode={theme as TTheme}
					disableElevation
					variant={'text'}
					fullWidth
					onClick={onClick}
					endIcon={
						isLoading ? (
							<CircularProgress
								sx={{
									'.MuiCircularProgress-circle': {
										stroke: '#00beb4'
									}
								}}
								thickness={5}
								size={15}
							/>
						) : (
							''
						)
					}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isBack) {
		return (
			<div className={className}>
				<StyledButton
					isBack
					isDarkMode={theme as TTheme}
					disableElevation
					variant={'text'}
					fullWidth
					onClick={onClick}
					startIcon={<West />}
				>
					{t('savedAddresses_back')}
				</StyledButton>
			</div>
		);
	}

	return (
		<div className={className}>
			<StyledButton
				isSmall={isSmall}
				className={className}
				ref={buttonRef}
				fullWidth
				isDarkMode={theme as TTheme}
				endIcon={
					isLoading ? (
						<CircularProgress
							sx={{
								'.MuiCircularProgress-circle': {
									stroke: '#FFFFFF'
								}
							}}
							thickness={5}
							size={15}
						/>
					) : (
						''
					)
				}
				disableElevation
				type={type}
				variant={'contained'}
				disabled={isDisabled}
				onClick={onClick}
			>
				{text}
			</StyledButton>
		</div>
	);
});

interface TwoFAIconProps {
	iconType: string;
	className: string;
}

const TwoFAIcon = ({ iconType, className }: TwoFAIconProps) => {
	if (iconType.toLowerCase() === 'email') {
		return (
			<div className={className}>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM12.0606 11.6829L5.64722 6.2377L4.35278 7.7623L12.0731 14.3171L19.6544 7.75616L18.3456 6.24384L12.0606 11.6829Z'
						fill='black'
					/>
				</svg>
			</div>
		);
	}
	if (iconType.toLowerCase() === 'phone') {
		return (
			<div className={className}>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M10 3H14C18.4183 3 22 6.58172 22 11C22 15.4183 18.4183 19 14 19V22.5C9 20.5 2 17.5 2 11C2 6.58172 5.58172 3 10 3Z'
						fill='#181A21'
					/>
				</svg>
			</div>
		);
	}
	if (iconType.toLowerCase() === 'googleauthenticator') {
		return (
			<div className={className}>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M16.5 1.5H7.5C6.90326 1.5 6.33097 1.73705 5.90901 2.15901C5.48705 2.58097 5.25 3.15326 5.25 3.75V20.25C5.25 20.8467 5.48705 21.419 5.90901 21.841C6.33097 22.2629 6.90326 22.5 7.5 22.5H16.5C17.0967 22.5 17.669 22.2629 18.091 21.841C18.5129 21.419 18.75 20.8467 18.75 20.25V3.75C18.75 3.15326 18.5129 2.58097 18.091 2.15901C17.669 1.73705 17.0967 1.5 16.5 1.5ZM7.5 3H16.5C16.6989 3 16.8897 3.07902 17.0303 3.21967C17.171 3.36032 17.25 3.55109 17.25 3.75V4.5H6.75V3.75C6.75 3.55109 6.82902 3.36032 6.96967 3.21967C7.11032 3.07902 7.30109 3 7.5 3ZM16.5 21H7.5C7.30109 21 7.11032 20.921 6.96967 20.7803C6.82902 20.6397 6.75 20.4489 6.75 20.25V19.5H17.25V20.25C17.25 20.4489 17.171 20.6397 17.0303 20.7803C16.8897 20.921 16.6989 21 16.5 21Z'
						fill='#181A21'
					/>
				</svg>
			</div>
		);
	}
	return <></>;
};

interface ITwoFAButtonProps {
	text: string;
	iconType: string;
	onClick: () => void;
}
const TwoFAButton = ({ text, onClick, iconType }: ITwoFAButtonProps) => {
	return (
		<button className={'twoFAButton'} onClick={onClick}>
			<div className='twoFAButtonContent'>
				<div className={'twoFAButtonContentIconText'}>
					<TwoFAIcon
						iconType={iconType}
						className='twoFAButtonImageContainer'
					/>
					<h1 className='twoFAButtonText'>{text}</h1>
				</div>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M13.0603 11.8891L8.81758 7.64644C8.42705 7.25592 8.42705 6.62276 8.81757 6.23223C9.20809 5.8417 9.84126 5.8417 10.2318 6.23222L15.8887 11.8891L10.2318 17.546C9.84125 17.9365 9.20809 17.9365 8.81757 17.546C8.42705 17.1555 8.42705 16.5223 8.81757 16.1318L13.0603 11.8891Z'
						fill='#BFC1C5'
					/>
				</svg>
			</div>
		</button>
	);
};

export { CustomButton, TwoFAButton };
