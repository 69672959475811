import { TBiometricManager } from '../types/types';

export const useTelegram = () => {
	//eslint-disable-next-line
	const tg = (window as any).Telegram.WebApp;
	const isTelegram = tg.initData.length > 0;
	const biometricManager: TBiometricManager = tg.BiometricManager;

	return {
		tg,
		isTelegram,
		biometricManager,
	};
};
