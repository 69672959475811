import { logout } from '../services/auth';
import { AppDispatch } from '../store';
import { ROUTES } from '../types/routes';
import { TCustomNavigate } from '../types/types';
import { NumericFormat } from 'react-number-format';

const mapClassnameForMobile = (className: string, isNeedAuthMainContainer?: boolean) => {
	if (window.innerWidth <= 480) {
		return className + ' topPlacement';
	}
	return className + (isNeedAuthMainContainer ? ' authMainContainer' : '');
};

const mapTranslationKeyHeaderByName = (pageName: string) => {
	switch (pageName) {
		case 'wallet':
			return 'wallet_title';
		case 'market':
			return 'assets_title';
		case 'exchange':
			return 'wallet_exchange';
		default:
			return '';
	}
};

const mapDictionaryByName = (dictionaryName: string) => {
	switch (dictionaryName) {
		case 'countries':
			return 'countries';
		case 'legalStatements':
			return 'documents';
		case 'help':
			return 'help';
		case 'community':
			return 'community';
		case 'assetIcons':
			return 'asseticons';
		default:
			return '';
	}
};

const formatNumber = (
	number: number,
	precision?: number,
	splitBy?: string,
	splitByPart?: number
) => {
	/*const numberStr = number?.toLocaleString('us-US', {
		minimumFractionDigits: precision,
		maximumFractionDigits: precision,
	});*/
	/*if (numberStr?.includes('')) {
		return numberStr;
	} else {
		return numberStr?.replaceAll(',', ' ')?.replaceAll('.', ',');
	}*/
	return (
		<NumericFormat
			displayType={'text'}
			value={
				splitBy
					? String(number)
							?.split(splitBy)
							[splitByPart as number]?.substring(
								0,
								splitByPart === 1 ? precision : undefined
							)
					: number
			}
			thousandSeparator={splitByPart === 1 ? '' : ' '}
			decimalSeparator={','}
			decimalScale={precision ? precision : 2}
		/>
	);
};

const mapResponseError = (
	response: Response,
	//eslint-disable-next-line
	responseJSON: any,
	navigate?: TCustomNavigate,
	dispatch?: AppDispatch
) => {
	const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

	if (response.status === 401 && responseJSON.code != 'VERIFICATION_CODE_INVALID') {
		dispatch && dispatch(logout());
		window.location.replace(ROUTES.AUTH.INDEX.replace(':locale', locale));
		throw responseJSON;
	}
	if (response.status === 503) {
		window.location.replace(ROUTES.MAINTAIN.INDEX.replace(':locale', locale));
		throw responseJSON;
	}
	throw responseJSON;
};

export {
	mapDictionaryByName,
	mapClassnameForMobile,
	mapResponseError,
	mapTranslationKeyHeaderByName,
	formatNumber,
};
