import { Helmet } from 'react-helmet-async';
import LoginOTPLayout from '../../components/auth/otp';
import { TITLES } from '../../types/titles';

const LoginOTP = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.AUTH.TWOFA}</title>
			</Helmet>
			<LoginOTPLayout />
		</>
	);
};

export default LoginOTP;
